import { LanguageResource } from "@ruter-as/web-components-and-tools";

interface FooterLanguageResource {
  customerService: string;
  contactUs: string;
  policiesAndPrivacy: string;
  cookies: string;
  privacy: string;
  policies: string;
  conditionsOfTravel: string;
  conditionsOfTransportation: string;
}

const footerLanguageResource: LanguageResource<FooterLanguageResource> = {
  nb: {
    customerService: "Kundeservice",
    contactUs: "Kontakt oss",
    policiesAndPrivacy: "Vilkår og personvern",
    cookies: "Informasjonskapsler",
    privacy: "Personvern",
    policies: "Vilkår",
    conditionsOfTravel: "Reisevilkår",
    conditionsOfTransportation: "Transportvilkår",
  },
  en: {
    customerService: "Customer service",
    contactUs: "Contact us",
    policiesAndPrivacy: "Terms and privacy",
    cookies: "Cookies",
    privacy: "Privacy",
    policies: "Terms and conditions",
    conditionsOfTravel: "Conditions of travel",
    conditionsOfTransportation: "Conditions of transportation",
  },
};

export default footerLanguageResource;
