import React from "react";
import { useAuthContext } from "src/AuthContext";
import { CallToActionLink } from "src/components/common/buttons";
import "./ContactUsLink.scss";

interface Props {
  text: string
}

const ContactUsLink: React.FC<Props> = ({ text }) => {
  const authContext = useAuthContext();
  const link = authContext.features.urls.contactUs;

  return (
    <div className="cta-actions">
      {link.type === "Internal" && (
        <CallToActionLink
          path={link.url}
          text={text}
          showIcon={true}
        />
      )}
      {link.type === "External" && (
        <CallToActionLink
          externalUrl={link.url}
          text={text}
          showIcon={true}
        />
      )}
    </div>
  );
};

export default ContactUsLink;
