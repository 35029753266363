import { LanguageResource, languageResourceBuilder, TwoParamsLanguageResourceTemplate } from "@ruter-as/web-components-and-tools";

interface HousingAssociationTicketFormLanguageResource {
  title: string
  orderTicket: string
  adult: string
  retired: string
  child: string
  profile: string
  errorExistingOpenTicket: string
  gapToSmall: TwoParamsLanguageResourceTemplate;
  acceptPrivay: string
  readPolicies: string
}

export const housingAsssociationTicketFormLanguageResource: LanguageResource<HousingAssociationTicketFormLanguageResource> = {
  nb: {
    title: "Bestill billett",
    orderTicket: "Bestill billett",
    adult: "Voksen",
    child: "Barn",
    retired: "Honnør",
    profile: "Reisende",
    errorExistingOpenTicket: "Det eksisterer allerede en gyldig eller ventende billett på dette telefonnummeret.",
    gapToSmall:
      languageResourceBuilder.twoParamTemplate("Bestilling av ny billett ble avvist, fordi det finnes en billett med sluttdato $p1$. " +
        "I henhold til reglene om karantenetid på 90 dager, kan du ikke bestille en billett med startdato før $p2$"),
    acceptPrivay:
      "Jeg bekrefter at beboberen har fått informasjon om behandling av personopplysninger og godkjenner Ruters vilkår, " +
      "samt at jeg som administrator har fått tillatelse til å inngå avtale på hans / hennes vegne.",
    readPolicies: "Les Ruters vilkår",
  },
  en: {
    title: "Order ticket",
    orderTicket: "Order ticket",
    adult: "Adult",
    child: "Child",
    retired: "Retired",
    profile: "Profile",
    errorExistingOpenTicket: "There already exists a pending or active ticket for this phonenumber.",
    gapToSmall: languageResourceBuilder.twoParamTemplate("Could not order a new ticket. A ticket that has expired on $p1$ already exists, according to our rules, the first available start date for a new ticket is $p2$"),
    acceptPrivay:
      "I hereby confirm that the resident has accepted ruters terms and policies, and that I have been given consent by the employee to order a ticket on his/hers behalf",
    readPolicies: "Read Ruters policies",
  },
};
