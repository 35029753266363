import { apiClient, formatter, useLanguageResource } from "@ruter-as/web-components-and-tools";
import { useEffect, useState } from "react";
import { useWatch } from "react-hook-form";
import { Zone } from "src/common/api/commonTypes/Zone";
import companyAgreementApi from "src/common/api/companyAgreementApi/companyAgreementApi";
import { ALL_ZONES_FROM } from "src/constants";
import { Product } from "src/types/Product";
import { ProductTemplate } from "src/types/ProductTemplate";
import { ProfileId } from "src/types/ProfileId";
import { formLanguageResource } from "./lang-resource";
import "./ProductPrice.scss";

interface Props {
  zones: Zone[]
  profile: ProfileId
  gray?: boolean;
}

const zonesIsMatchingProductFilter = (product: Product, zoneFromId?: string, zoneToId?: string): boolean => {
  if (!product.filters) {
    return false;
  }
  const { zoneListMustEqual, zoneListMustBeSubsetOf } = product.filters;

  if (zoneListMustEqual) {
    return zoneListMustEqual.length === 1 && zoneListMustEqual[0] === zoneFromId && zoneListMustEqual[0] === zoneToId;
  }

  if (zoneListMustBeSubsetOf) {
    return zoneListMustBeSubsetOf.some(x => x === zoneFromId) && zoneListMustBeSubsetOf.some(x => x === zoneToId);
  }

  return false;
};

const getMatchingProduct = (products: Product[], zoneFrom: string, zoneTo: string, zones: Zone[], profile: string): Product => {
  const filteredProducts = products.filter(product => product.profileId === profile);
  const groupedFilteredProducts = {
    productsWithFilter: filteredProducts.filter(x => x.filters),
    productsWithoutFilter: filteredProducts.filter(x => x.filters === null),
  };

  const zoneFromObj = zones.find(x => x.name === zoneFrom);
  const zoneToObj = zones.find(x => x.name === zoneTo);

  if (groupedFilteredProducts.productsWithFilter.length > 0) {
    const exactMatch = groupedFilteredProducts.productsWithFilter.find(x => zonesIsMatchingProductFilter(x, zoneFromObj?.id, zoneToObj?.id));

    if (exactMatch) {
      return exactMatch;
    }
  }

  return groupedFilteredProducts.productsWithoutFilter[0];
};

const ProductPrice: React.FC<Props> = ({ gray, zones, profile }) => {
  const lang = useLanguageResource(formLanguageResource);
  const [productTemplate, setProductTemplate] = useState<ProductTemplate>();

  useEffect(() => {
    const fetchPrices = async () => {
      const response = await apiClient.request(companyAgreementApi.product.getV2Products());
      if (response.type === "success") {
        setProductTemplate(response.result.find(x => x.tags.includes("bigcustomer:subscriptionTicket")));
      } else {
        setProductTemplate(() => {
          throw response.error;
        });
      }
    };
    fetchPrices();
    return () => { setProductTemplate(undefined); };
  }, []);

  const zoneFrom = useWatch({ name: "zoneFrom" });
  const zoneTo = useWatch({ name: "zoneTo" });

  let zoneCount = 1;
  if (zoneFrom === ALL_ZONES_FROM || zoneFrom === null) {
    zoneCount = 3;
  } else if (zoneFrom !== zoneTo) {
    zoneCount = 2;
  }

  const product = productTemplate && getMatchingProduct(productTemplate.products, zoneFrom, zoneTo, zones, profile);
  const priceInfo = product && product.prices.length > 1 ? product.prices.find(x => x.nrOfZones === zoneCount) : product?.prices[0];
  const price = priceInfo ? priceInfo.priceInclVAT / 12 : undefined;

  const formattedPrice = price ? formatter.number.integer(Math.floor(price)) : "-";
  const formattedDate = formatter.date.toMonthAndYear(new Date());

  const cssClassNames = ["components-common-form-productpricefield"];
  if (gray) {
    cssClassNames.push("gray");
  }

  return (
    <div className={cssClassNames.join(" ")}>
      <div className="label">{lang.productName(formattedDate)}</div>
      <div>
        <span className="price" data-test-id="product-price-field">
          {formattedPrice}
        </span>
        <span className="label">kr</span>
      </div>
    </div>
  );
};

export default ProductPrice;