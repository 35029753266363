const profiles = ["ADULT", "CHILD", "SENIOR", "STUDENT", "YOUTH", "YOUNG_ADULT", "BIKE", "PROFILE_200", "PROFILE_201", "PROFILE_202", "PROFILE_203", "MILITARY", "MOTORBIKE_WITH_DRIVER", "CAR_UNDER_6M_WITH_DRIVER", "CAR_6M_TO_8M_WITH_DRIVER", "CAR_8M_TO_10M_WITH_DRIVER", "CAR_ELECTRIC_WITH_DRIVER", "MILITARY_CONSCRIPTION", "UNKNOWN"] as const;
export type ProfileId = typeof profiles[number];

export const parseProfileV2 = (value: string | number): ProfileId => {
  if (profiles.includes(value as ProfileId)) {
    return value as ProfileId;
  }
  switch (value) {
    case 1: return "ADULT";
    case 2: return "CHILD";
    case 3: return "STUDENT";
    case 4: return "SENIOR";
    case 32: return "YOUTH";
    case 45: return "YOUNG_ADULT";
    case 81: return "BIKE";
    case 200: return "PROFILE_200";
    case 201: return "PROFILE_201";
    case 202: return "PROFILE_202";
    case 203: return "PROFILE_203";
    case 10: return "MILITARY";
    case 22: return "MOTORBIKE_WITH_DRIVER";
    case 82: return "CAR_UNDER_6M_WITH_DRIVER";
    case 151: return "CAR_6M_TO_8M_WITH_DRIVER";
    case 152: return "CAR_8M_TO_10M_WITH_DRIVER";
    case 153: return "CAR_ELECTRIC_WITH_DRIVER";
    case 80: return "MILITARY_CONSCRIPTION";
    default: return "UNKNOWN";
  }
};
