import {
  apiClient, ButtonGroup,
  Container,
  DateFormInput,
  MediaFormInput,
  Message,
  PhoneFormInput,
  TextFormInput, useLanguageResource,
} from "@ruter-as/web-components-and-tools";
import * as Sentry from "@sentry/react";
import { addMonths } from "date-fns";
import parsePhoneNumberFromString from "libphonenumber-js/max";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useAuthContextAuthenticated, useValidFreeTicketAgreementService } from "src/AuthContext";
import freeTicketApi from "src/common/api/freeTicketApi/freeTicketApi";
import { formFieldsLanguageResource } from "src/common/form-fields-language-resource";
import successAlert, { failAlert } from "src/common/toastr";
import { TicketHolderType } from "src/types/freeTicketAgreement/ticketHolderType";
import { Zone } from "../../../common/api/commonTypes/Zone";
import FreeTicketFormData, { CreateTicketValidationErrors, ErrorType, GeneralErrorType, PerTicketRequestErrorType } from "../../../common/api/freeTicketApi/freeTicketFormData";
import FreeTicketPostContract from "../../../common/api/freeTicketApi/freeTicketPostContract";
import { ensureExpiryDateIsNotInThePastAndFormatForBackend, getExpiryDates } from "../../../common/expiryDate";
import MediaType from "../../../types/mediaType";
import { CancelButton, SubmitButton } from "../../common/buttons";
import DropdownInput from "../../common/form-hooks/DropdownInput";
import "./FreeTicketForm.scss";
import { freeTicketLanguageResource } from "./lang-resource";
import TicketProfileInput from "./TicketProfileInput";

interface ZoneInformationProps {
  zones: Zone[],
  selectedZone: string;
}

const zoneIdsForAdditionalInformation = ["AKT:TariffZone:900", "AKT:TariffZone:950"];

const ZoneInformation: React.FC<ZoneInformationProps> = ({ selectedZone, zones }) => {
  const lang = useLanguageResource(freeTicketLanguageResource);
  const additionalZonesText = zones.filter(z => z.name !== selectedZone).map(z => z.name).join(",");

  return <Message skin="info" data-test-id="zone-information">{lang.additionalZoneInformation(additionalZonesText)}</Message>;
};

const FreeTicketForm: React.FC = () => {
  const authContext = useAuthContextAuthenticated();
  const freeTicketService = useValidFreeTicketAgreementService();
  const formLang = useLanguageResource(formFieldsLanguageResource);
  const lang = useLanguageResource(freeTicketLanguageResource);
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState(false);
  const [isBackendError, setBackendError] = useState<ErrorType>();
  const [zones, setZones] = useState<null | Zone[]>(null);
  const dates = getExpiryDates(32, formLang.today, 7);

  useEffect(() => {
    const fetchZones = async () => {
      const response = await apiClient.request(freeTicketApi.distance.zones.get());

      if (response.type === "success") {
        setZones(response.result);
      } else {
        setZones(() => {
          throw response.error;
        });
      }
    };

    fetchZones();
    return () => { setZones(null); };
  }, []);

  const findInvoiceRef = useCallback(
    (agreementNumberInput: string) => {
      const agreementFromInput = freeTicketService.invoiceRefs.find((x) => x.agreementNumber === agreementNumberInput);
      return agreementFromInput?.invoiceReference || `${lang.missingDepartmentName} (${agreementNumberInput})`;
    },
    [lang.missingDepartmentName, freeTicketService],
  );

  const agreementsOptions = freeTicketService.invoiceRefs
    .filter((x) => x.endDate === null || x.endDate > new Date())
    .map((x) => ({ value: x.agreementNumber, text: findInvoiceRef(x.agreementNumber) }));

  agreementsOptions.sort((a, b) => {
    if (a.text < b.text) {
      return -1;
    }
    if (a.text > b.text) {
      return 1;
    }
    return 0;
  });

  const formMethods = useForm<FreeTicketFormData>({
    defaultValues: {
      phone: "",
      firstName: "",
      lastName: "",
      employeeId: "",
      dateOfBirth: "",
      startDate: freeTicketService.showDateInput ? "" : dates[0].value,
    },
  });

  const mediaType = useWatch({ name: "mediaType", defaultValue: undefined, control: formMethods.control });
  const ticketHolderType = useWatch({ name: "ticketHolderType", defaultValue: undefined, control: formMethods.control });
  const zoneFrom = useWatch({ name: "zoneFrom", defaultValue: undefined, control: formMethods.control });
  const zoneTo = useWatch({ name: "zoneTo", defaultValue: undefined, control: formMethods.control });

  if (zones === null) {
    return null;
  }

  const mapToPostContract = (data: FreeTicketFormData): FreeTicketPostContract => {

    let zoneFromName = data.zoneFrom;
    let zoneToName = data.zoneTo;
    let validAllZones = false;

    if (freeTicketService.singleZoneHasAdditionalZone) {
      if (zoneToName === zoneFromName) {
        if (zoneFromName === "Kristiansand" || zoneFromName === "Vennesla") {
          zoneFromName = "Kristiansand";
          zoneToName = "Vennesla";
        }
      }
    }

    const zoneIdFrom = zones.find(x => x.name === zoneFromName)?.id || null;
    const zoneIdTo = zones.find(x => x.name === zoneToName)?.id || null;

    if (zoneIdFrom === null) {
      validAllZones = true;
    }

    if (mediaType === MediaType.TRAVEL_CARD) {
      const contract: FreeTicketPostContract = {
        agreementNumber: data.agreement,
        firstName: data.firstName,
        lastName: data.lastName,
        mediaType: data.mediaType,
        startDate: ensureExpiryDateIsNotInThePastAndFormatForBackend(data.startDate).toISOString(),
        ticketHolderType: data.ticketHolderType,
        employeeId: data.employeeId,
        zoneFrom: zoneFromName,
        zoneTo: zoneToName,
        dateOfBirth: moment(data.dateOfBirth, "DD.MM.YYYY", true).toDate().toISOString(),
        profile: data.profile,
        zoneIdFrom: zoneIdFrom,
        zoneIdTo: zoneIdTo,
        validAllZones,
      };
      if (freeTicketService.hasTravelCardNumber) {
        contract.cardNumber = data.cardNumber;
      }
      return contract;
    }

    const phone = parsePhoneNumberFromString(data.phone);

    if (!phone || !phone.isValid()) {
      throw new Error("invalid phone number should be impossible as it is validated by the form");
    }

    return {
      agreementNumber: data.agreement,
      firstName: data.firstName,
      lastName: data.lastName,
      phone: phone.nationalNumber.toString(),
      phoneCountryCode: `+${phone.countryCallingCode}`,
      mediaType: data.mediaType,
      startDate: ensureExpiryDateIsNotInThePastAndFormatForBackend(data.startDate).toISOString(),
      ticketHolderType: data.ticketHolderType,
      employeeId: data.employeeId,
      zoneFrom: zoneFromName,
      zoneTo: zoneToName,
      dateOfBirth: moment(data.dateOfBirth, "DD.MM.YYYY", true).toDate().toISOString(),
      profile: data.profile,
      zoneIdFrom: zoneIdFrom,
      zoneIdTo: zoneIdTo,
      validAllZones,
    };
  };

  const onSubmit = async (data: FreeTicketFormData) => {
    setSubmitting(true);
    setBackendError(undefined);

    const contract = mapToPostContract(data);

    const response = await apiClient.request(freeTicketApi.ticket.create.post(contract));

    if (response.type === "success") {
      successAlert(lang.orderTicketSuccess);
      navigate("/fribilletter");
    } else if (response.type === "HttpError" && response.responseStatus === 409) {
      failAlert(lang.orderTicketFail);
      setBackendError("DUPLICATE_TICKET");
      setSubmitting(false);
    } else if (response.type === "HttpError" && response.responseStatus === 422) {
      failAlert(lang.orderTicketFail);

      const responseBody = response.responseBody as CreateTicketValidationErrors;
      let isUnknown = true;
      if (responseBody.general.length > 0 && responseBody.general.some(x => x === GeneralErrorType.TRAVEL_CARD_NO_COMPANY_POST_ADDRESS)) {
        isUnknown = false;
        setBackendError("TRAVEL_CARD_NO_COMPANY_POST_ADDRESS");
      }
      if (responseBody.perRequest.length > 0 && responseBody.perRequest.some(x => x === PerTicketRequestErrorType.CONFLICTING_TICKET)) {
        isUnknown = false;
        setBackendError("CONFLICTING_TICKET");
      }
      if (isUnknown) {
        Sentry.captureException(response.error, { extra: { responseBody: response.responseBody } });
        setBackendError("UNKNOWN");
      }
      setSubmitting(false);

    } else {
      failAlert(lang.orderTicketFail);
      setSubmitting(() => {
        throw response.error;
      });
    }
  };

  const renderErrorMessage = (error: ErrorType) => {
    switch (error) {
      case "CONFLICTING_TICKET":
        return (
          <Message skin="danger" data-test-id="conflicting-ticket-error-message" style={{ marginTop: "1rem" }}>
            {ticketHolderType === TicketHolderType.EMPLOYEE ? lang.conflictingTicket.employeeRelation : lang.conflictingTicket.general}
          </Message>
        );
      case "DUPLICATE_TICKET":
        return (
          <Message skin="danger" data-test-id="is-duplicate-error-message" style={{ marginTop: "1rem" }}>
            {lang.duplicateTicket}
          </Message>
        );
      case "TRAVEL_CARD_NO_COMPANY_POST_ADDRESS":
        return (
          <Message skin="danger" data-test-id="missing-post-address-error-message" style={{ marginTop: "1rem" }}>
            {lang.missingCompanyPostAddress}
          </Message>
        );
      case "UNKNOWN":
        return (
          <Message skin="danger" data-test-id="unkown-error-message" style={{ marginTop: "1rem" }}>
            {lang.genericError}
          </Message>
        );
    }
  };


  const relations = [
    { value: "EMPLOYEE", text: lang.employee },
    { value: "RETIRED", text: lang.retired },
    { value: "FAMILY_MEMBER", text: lang.familyMember },
  ];

  const getZoneById = (id: string) => {
    const x = zones.find(zone => zone.id === id);
    if (!x) throw new Error(`Could not find zone with id: ${id}`);
    return x;
  };

  const isSingleZone = zoneFrom && zoneFrom === zoneTo;
  const zonesWithAdditionalInformation = freeTicketService.singleZoneHasAdditionalZone && zoneIdsForAdditionalInformation.map(getZoneById);

  const showAdditionalZoneInformation = isSingleZone && zonesWithAdditionalInformation && zonesWithAdditionalInformation.find(zone => zone.name === zoneFrom);


  return (
    <Container width="xs" className="components-freeticketagreement-freeticket" data-test-id="components-freeticketagreement-freeticket">
      <h1>{lang.title}</h1>

      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <MediaFormInput
            name="mediaType"
            label={lang.chooseMediaType}
            option1={{ value: MediaType.MOBILE_TICKET, disabled: false }}
            option2={{ value: MediaType.TRAVEL_CARD, disabled: false }}
          />
          {mediaType && (
            <DropdownInput name="agreement" label={lang.agreement}>
              {agreementsOptions.map((a) => (
                <option key={a.value} value={a.value}>
                  {a.text}
                </option>
              ))}
            </DropdownInput>
          )}
          {mediaType === MediaType.MOBILE_TICKET && <PhoneFormInput name="phone" label={formLang.mobile} required mobileOnly />}
          {mediaType === MediaType.TRAVEL_CARD && freeTicketService.hasTravelCardNumber && (<TextFormInput name="cardNumber" label={lang.cardNumber} required exactLengths={[9, 10, 16]} numericOnly />)}
          {mediaType && <TextFormInput name="firstName" label={formLang.firstName} required minLength={2} maxLength={50} />}
          {mediaType && <TextFormInput name="lastName" label={formLang.lastName} required minLength={2} maxLength={50} />}
          {mediaType && <TextFormInput name="employeeId" label={lang.employeeId} required minLength={2} maxLength={20} />}
          {mediaType && <DateFormInput name="dateOfBirth" label={formLang.birthDate} required maxDate={new Date()} />}
          {mediaType &&
            (freeTicketService.showDateInput ? (
              <DateFormInput name="startDate" label={formLang.startDate} minDate={new Date()} maxDate={addMonths(new Date(), 6)} required />
            ) : (
              <DropdownInput name="startDate" label={formLang.startDate}>
                {dates.map((d) => (
                  <option value={d.value} key={d.value}>
                    {d.text}
                  </option>
                ))}
              </DropdownInput>
            ))}
          {mediaType && (
            <DropdownInput name="ticketHolderType" label={formLang.relation}>
              {relations.map((r) => (
                <option value={r.value} key={r.value}>
                  {r.text}
                </option>
              ))}
            </DropdownInput>
          )}
          {mediaType && <TicketProfileInput />}
          {mediaType &&
            <>
              {authContext.features.getZoneInput(zones)}
              {showAdditionalZoneInformation && <ZoneInformation selectedZone={zoneFrom} zones={zonesWithAdditionalInformation} />}
            </>
          }

          {mediaType && (
            <>
              {isBackendError && renderErrorMessage(isBackendError)}
              <ButtonGroup>
                <SubmitButton text={lang.orderTicket} submitting={submitting} />
                <CancelButton onClick={() => navigate("/fribilletter")} />
              </ButtonGroup>
            </>
          )}
        </form>
      </FormProvider>
    </Container>
  );
};

export default FreeTicketForm;
