import { LanguageResource, languageResourceBuilder, OneParamLanguageResourceTemplate } from "@ruter-as/web-components-and-tools";

export interface EmployeeTicketsLanguageResource {
  mobileNumber: string;
  companyName: string;
  changeInfo: string;
  newTicket: string;
  businessTicket: string;
  tickets: string;
  addOns: string;
  status: string;
  validFrom: string;
  validTo: string;
  pickedUp: string;
  pricePerMonth: string;
  zones: string;
  invoiceReference: string;
  changeZone: string;
  cancelPendingTicket: string;
  cancelActiveTicket: string;
  yes: string;
  no: string;
  changeInfoSuccess: string;
  from: string;
  pendingTicketCancelled: string;
  activeTicketCancelled: string;
  changeDate: string;
  zoneChanged: string;
  and: string;
  appId: string;
  confirmPendingTicketCancel: string;
  confirmResendPickupCode: string;
  confirmResendTicket: string;
  zoneOne: string;
  updating: string;
  orderId: string;
  pickupCode: string;
  resendTicket: string;
  resendTicketSucceeded: string;
  resendTicketFailed: string;
  resendPickupCode: string;
  sendPickupCode: string;
  sendPickupCodeFailed: string;
  sendPickupCodeSucceeded: string;
  cancelActiveTicketInfoTitle: string;
  cancelActiveTicketInfoPrivacy: OneParamLanguageResourceTemplate;
  cacnelActiveTicketInfoReactivation: OneParamLanguageResourceTemplate;
  cancelAddOnSuccess: OneParamLanguageResourceTemplate;
  cancelAddOnFailed: OneParamLanguageResourceTemplate;
  cancelPendingAddOn: string;
  cancelActiveAddOn: string;
  payroll: string;
  amount: string;
  invoiceMonth: string;
  oneZone: OneParamLanguageResourceTemplate;
  twoZones: OneParamLanguageResourceTemplate;
  allZones: OneParamLanguageResourceTemplate;
  orderNumber: string;
  expired: string;
  statuses: {
    active: string;
    pending: string;
    expired: string;
    cancelled: string;
    failed: string;
    unknown: string;
  };
  submitFailure: string;
  noChangeToZoneValidationError: string;
  profile: string
}

const employeeTicketsLanguageResource: LanguageResource<EmployeeTicketsLanguageResource> = {
  nb: {
    mobileNumber: "Mobilnummer",
    companyName: "Bedrift",
    changeInfo: "Endre info",
    newTicket: "By bedriftsbillett",
    businessTicket: "Bedriftsbillett",
    tickets: "Billetter",
    addOns: "Tilleggtjenester",
    status: "Status",
    validFrom: "Gyldig fra",
    validTo: "Gyldig til",
    pickedUp: "Hentet",
    pricePerMonth: "Pris/mnd",
    zones: "Soner",
    invoiceReference: "Faktura ref.",
    changeZone: "Endre sone",
    cancelPendingTicket: "Kanseller billett",
    cancelActiveTicket: "Avslutt billett",
    yes: "Ja",
    no: "Nei",
    changeInfoSuccess: "Ansattdetaljer endret!",
    from: "Gjelder fra",
    pendingTicketCancelled: "Billett er kansellert!",
    activeTicketCancelled: "Billett er avsluttet!",
    changeDate: "Dato for endring",
    zoneChanged: "Soner endret!",
    and: "og",
    appId: "App id",
    confirmPendingTicketCancel: "Er du sikker på at du ønsker å kansellere billetten?",
    confirmResendPickupCode: "Vil du sende ut ny SMS med hentekode til den ansatte?",
    confirmResendTicket:
      "Vil du lage ny billett og sende ut SMS med hentekode til den ansatte? Den opprinnelige billetten vil bli avsluttet umiddelbart.",
    zoneOne: "sone 1",
    updating: "Oppdaterer",
    orderId: "Billettnummer",
    pickupCode: "Hentekode",
    resendTicket: "Send billett på nytt",
    resendTicketFailed: "Send billett på nytt feilet!",
    resendTicketSucceeded: "Billett sendt på nytt!",
    resendPickupCode: "Send hentekode på nytt",
    sendPickupCode: "Send hentekode",
    sendPickupCodeFailed: "Send hentekode feilet!",
    sendPickupCodeSucceeded: "Hentekode sendt!",
    cancelActiveTicketInfoTitle: "Vedrørende avslutning av bedriftsbillett",
    cancelActiveTicketInfoPrivacy: languageResourceBuilder.oneParamTemplate("Billetten avsluttes $p1$. Personopplysninger slettes 3 måneder senere."),
    cacnelActiveTicketInfoReactivation: languageResourceBuilder.oneParamTemplate("Den ansatte kan ikke starte en ny bedriftsbillet før $p1$."),
    cancelAddOnSuccess: languageResourceBuilder.oneParamTemplate("$p1$ avsluttet!"),
    cancelAddOnFailed: languageResourceBuilder.oneParamTemplate("Det skjedde en feil ved avslutting av $p1$"),
    cancelPendingAddOn: "Kanseller tjeneste",
    cancelActiveAddOn: "Avslutt tjeneste",
    payroll: "Siste fem lønnstrekk",
    amount: "Beløp",
    invoiceMonth: "Fakturamnd.",
    oneZone: languageResourceBuilder.oneParamTemplate("En sone, $p1$"),
    twoZones: languageResourceBuilder.oneParamTemplate("To soner, $p1$"),
    allZones: languageResourceBuilder.oneParamTemplate("Alle soner, $p1$"),
    orderNumber: "Billettnummer",
    expired: "Utløpte",
    statuses: {
      active: "Gyldig",
      pending: "Ventende",
      expired: "Utløpt",
      cancelled: "Kansellert",
      failed: "Feilet",
      unknown: "Ukjent",
    },
    submitFailure: "Det skjedde en feil ved lagring av skjema",
    noChangeToZoneValidationError: "Du må gjøre en endring på soner for å lagre.",
    profile: "Reisende",
  },
  en: {
    mobileNumber: "Mobile number",
    companyName: "Company",
    changeInfo: "Change info",
    newTicket: "New business ticket",
    businessTicket: "Business ticket",
    tickets: "Tickets",
    addOns: "Additional services",
    status: "Status",
    validFrom: "Valid from",
    validTo: "Valid to",
    pickedUp: "Fetched",
    pricePerMonth: "Price/month",
    zones: "Zones",
    invoiceReference: "Invoice ref.",
    changeZone: "Change zone",
    cancelPendingTicket: "Cancel ticket",
    cancelActiveTicket: "Terminate ticket",
    yes: "Yes",
    no: "No",
    changeInfoSuccess: "Employee details updated!",
    from: "From",
    pendingTicketCancelled: "Ticket is cancelled",
    activeTicketCancelled: "Ticket is terminated!",
    changeDate: "Date for change",
    zoneChanged: "Zones changed!",
    and: "and",
    appId: "App id",
    confirmPendingTicketCancel: "Are you sure you want to cancel the ticket?",
    confirmResendPickupCode: "Are you sure you want to send an SMS with a new pickup code to the employee?",
    confirmResendTicket:
      "Are you sure you want to create a new ticket and send an SMS with a new pickup code to the employee? The original ticket will be cancelled immediately.",
    zoneOne: "zone 1",
    updating: "Updating",
    orderId: "Ticket number",
    pickupCode: "Pickup code",
    resendTicket: "Re-send ticket",
    resendTicketSucceeded: "Ticket re-sent",
    resendTicketFailed: "Re-send ticket failed!",
    resendPickupCode: "Re-send pickup code",
    sendPickupCode: "Send pickup code",
    sendPickupCodeFailed: "Send pickup code failed!",
    sendPickupCodeSucceeded: "Pickup code sent!",
    cancelActiveTicketInfoTitle: "Regarding cancellation of ticket.",
    cancelActiveTicketInfoPrivacy:
      languageResourceBuilder.oneParamTemplate("The ticket will be cancelled on $p1$. Stored information about the employee holding this ticket, will be deleted 3 months later."),
    cacnelActiveTicketInfoReactivation: languageResourceBuilder.oneParamTemplate("The employee can not start a new ticket before $p1$."),
    cancelAddOnSuccess: languageResourceBuilder.oneParamTemplate("$p1$ cancelled!"),
    cancelAddOnFailed: languageResourceBuilder.oneParamTemplate("An error occured while we tried to cancel $p1$"),
    cancelPendingAddOn: "Cancel service",
    cancelActiveAddOn: "Terminate service",
    payroll: "Last 5 salary deductions",
    amount: "Amount",
    invoiceMonth: "Invoice month",
    oneZone: languageResourceBuilder.oneParamTemplate("One zone, $p1$"),
    twoZones: languageResourceBuilder.oneParamTemplate("Two zones, $p1$"),
    allZones: languageResourceBuilder.oneParamTemplate("All zones, $p1$"),
    orderNumber: "Ticket number",
    expired: "Expired",
    statuses: {
      active: "Valid",
      pending: "Pending",
      expired: "Expired",
      cancelled: "Cancelled",
      failed: "Failed",
      unknown: "Unknown",
    },
    submitFailure: "An error occured while submitting this form.",
    noChangeToZoneValidationError: "You need to change zones in order to save.",
    profile: "Profile",
  },
};

export default employeeTicketsLanguageResource;