import { mapLocaleText, type LocaleText, type LocaleTextJson } from "./localeText";
import MediaType, { parseMediaType } from "./mediaType";
import { mapPrice, Price, PriceJson } from "./Price";
import { parseProfileV2, ProfileId } from "./ProfileId";

export interface ProductJson {
  id: number
  profileDescription: LocaleTextJson
  profileId: number
  profileName: LocaleTextJson
  prices: PriceJson[]
}

interface Filter {
  zoneListMustEqual: string[] | null
  zoneListMustBeSubsetOf: string[] | null
  zoneListMustBeSupersetOf: string[] | null
}

interface PriceVariableAmountJson {
  priceContextId: string
  minAmount: number
  maxAmount: number
  vatPercent: number
  description: string
}

export interface ProductV2Json {
  id: string
  compoundId: string
  compoundIdAlternate: string
  calendarRef: null | string
  productTemplateIdV1: number | null
  presetZoneIds: string[] | null
  prices: PriceJson[]
  profileNamePlural: LocaleTextJson
  profileDescription: LocaleTextJson
  profileId: string
  profileName: LocaleTextJson
  serviceProfileId: string
  userDetailId: string
  isCarnetProduct: boolean
  mediaTypes: string[]
  productIdV1: number | null
  nrOfMinutesValid: Array<{ nrOfMinutesValid: number, nrOfZones: number }> | null
  nrOfZonesGetAll: number | null
  filters: Filter | null
  profileIdV1: number | null
  priceVariableAmount: PriceVariableAmountJson | null
}

export interface Product {
  id: string
  profileDescription: LocaleText
  profileId: ProfileId
  profileName: LocaleText
  prices: Price[]
  presetZoneIds: string[] | null
  mediaTypes: MediaType[]
  nrOfZonesGetAll: number
  version: "V1" | "V2"
  filters: Filter | null
  maxPrice: number | null
}

export const mapProduct = (json: ProductJson | ProductV2Json, mediaTypes: string[], nrOfZonesGetAll: number | null): Product => {
  const version = (typeof json.id === "string") ? "V2" : "V1";

  const presetZoneIds = "presetZoneIds" in json ? json.presetZoneIds : null;
  const mediaTypesJson = "mediaTypes" in json ? json.mediaTypes : mediaTypes;
  const nrOfZonesGetAllJson = "nrOfZonesGetAll" in json ? json.nrOfZonesGetAll : nrOfZonesGetAll;
  const priceVariableAmount = "priceVariableAmount" in json ? json.priceVariableAmount : null;
  return {
    id: json.id.toString(),
    profileDescription: mapLocaleText(json.profileDescription),
    profileId: parseProfileV2(json.profileId),
    profileName: mapLocaleText(json.profileName),
    prices: json.prices.map(mapPrice),
    presetZoneIds,
    mediaTypes: mediaTypesJson.map(parseMediaType),
    nrOfZonesGetAll: nrOfZonesGetAllJson || 99,
    version,
    filters: "filters" in json ? json.filters : null,
    maxPrice: priceVariableAmount ? priceVariableAmount.maxAmount : null,
  };
};
