import { parseProfileV2, ProfileId } from "src/types/ProfileId";
import { SearchResult } from "../../../types/SearchResult";

export interface OrderJson {
  agreementNumber: string;
  appId: string;
  creationConfirmed: boolean;
  dateOrdered: string;
  documentVersion: number;
  downloadedToPhone: boolean;
  employeeBirthDate: null;
  employeeId: string;
  expirationDate: null | string;
  firstName: string;
  id: string;
  lastName: string;
  nodOrderStatusId: number;
  nrOfZones: number;
  orderStatusId: number;
  ownerId: number;
  parentOrderId: number;
  payerId: null;
  phone: string;
  phoneCountryCode: string;
  pickupCode: null | string;
  pricePerMonth: number;
  productId: number;
  productPrice: number;
  productTemplateId: number;
  productVatAmount: number;
  profileId: number;
  startDate: string;
  status: string;
  ticketHolderType: null;
  totalPrice: number;
  zoneFrom: string;
  zoneTo: string;
  zoneVia: string;
  zones: Array<string>;
  invoiceReference: string;
  profileIdV2: string | null;
  profileName: string | null;
}

export interface CompanyAgreementOrder {
  agreementNumber: string;
  appId: string;
  creationConfirmed: boolean;
  dateOrdered: string;
  documentVersion: number;
  downloadedToPhone: boolean;
  employeeBirthDate: null;
  employeeId: string;
  expirationDate: null | Date;
  firstName: string;
  id: string;
  lastName: string;
  nodOrderStatusId: number;
  nrOfZones: number;
  orderStatusId: number;
  ownerId: number;
  parentOrderId: number;
  payerId: null;
  phone: string;
  phoneCountryCode: string;
  pickupCode: string;
  pricePerMonth: number;
  productId: number;
  productPrice: number;
  productTemplateId: number;
  productVatAmount: number;
  profileId: ProfileId;
  profileName: string | null;
  startDate: Date;
  status: string;
  ticketHolderType: null;
  totalPrice: number;
  zoneFrom: string;
  zoneTo: string;
  zoneVia: string;
  zones: Array<string>;
  invoiceReference: string;
}

export const mapCompanyAgreementOrder = (json: OrderJson): CompanyAgreementOrder => ({
  ...json,
  expirationDate: json.expirationDate ? new Date(json.expirationDate) : null,
  startDate: new Date(json.startDate),
  pickupCode: json.pickupCode || "",
  profileId:  parseProfileV2(json.profileIdV2 || json.profileId),

});

export const mapCompanyAgreementOrderSearchResult = (json: SearchResult<OrderJson>): SearchResult<CompanyAgreementOrder> => ({
  totalEntries: json.totalEntries,
  matches: json.matches.map(mapCompanyAgreementOrder),
});

export const getValidOrder = (changes?: Partial<OrderJson>): OrderJson => ({
  agreementNumber: "1-3369898706",
  appId: "HVRV-GDJS-83",
  creationConfirmed: true,
  dateOrdered: "2019-06-20T07:13:59Z",
  documentVersion: 2,
  downloadedToPhone: true,
  employeeBirthDate: null,
  employeeId: "kiha",
  expirationDate: null,
  firstName: "firstName",
  id: "12223359",
  lastName: "lastName",
  nodOrderStatusId: 6,
  nrOfZones: 2,
  orderStatusId: 82,
  ownerId: 505,
  parentOrderId: 0,
  payerId: null,
  phone: "98064216",
  phoneCountryCode: "+47",
  pickupCode: null,
  pricePerMonth: 1174,
  productId: 21026,
  productPrice: 13290,
  productTemplateId: 3,
  productVatAmount: 1423.9286,
  profileId: 1,
  startDate: "2019-06-30T21:59:59Z",
  status: "cancelled",
  ticketHolderType: null,
  totalPrice: 13290,
  zoneFrom: "1",
  zoneTo: "2S",
  zoneVia: "",
  zones: ["1", "2S"],
  invoiceReference: "TEST",
  profileIdV2: "ADULT",
  profileName: "Voksen",
  ...changes,
});