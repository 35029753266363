export interface PriceJson {
  nrOfZones: number
  priceExclVAT: number
  priceInclVAT: number
  vat: number
  vatPercent: number
  priceContextId?: null | undefined
}

export interface Price {
  nrOfZones: number
  priceExclVAT: number
  priceInclVAT: number
  vat: number
}

export const mapPrice = (json: PriceJson): Price => ({
  nrOfZones: json.nrOfZones,
  priceInclVAT: json.priceInclVAT,
  priceExclVAT: json.priceExclVAT,
  vat: json.vat,
});
