enum MediaType {
  MOBILE_TICKET = "MOBILE_TICKET",
  TRAVEL_CARD = "TRAVEL_CARD",
}

export const parseMediaType = (value: string) => {
  const parsed = Object.values(MediaType).find((x) => x === value);

  if (parsed === undefined) {
    throw new Error("Could not determine UserState");
  }
  return parsed;
};

export default MediaType;
