import { Tenant } from "src/types/features/Tenant";
import { AuthCompany } from "../AuthCompany";



export interface Urls {
  cookies: string,
  travelTerms: string,
  privacy: string
  contactUs: UrlType
  terms?: UrlType
}

interface UrlType {
  type: "Internal" | "External",
  url: string
}

export const getExternalContactUsUrl = (tenant: Tenant): string => {
  switch (tenant) {
    case Tenant.Ruter:
      return "https://ruter.no/kjop-billett/storkunde/skjemaoversikt/stille-et-sporsmal/#page=form";
    case Tenant.Brakar:
      return "https://brakar.no/kundesenter/";
    case Tenant.Akt:
      return "https://www.akt.no/kundeservice/kontakt-oss/";
  }
};

const getTravelTermsUrl = (tenant: Tenant) => {
  switch (tenant) {
    case Tenant.Ruter:
      return "https://ruter.no/fa-hjelp/vilkar/reisevilkar/";
    case Tenant.Akt:
      return "https://www.akt.no/kundeservice/rettigheter-og-regler/transportvilkar-for-akt/";
    case Tenant.Brakar:
      return "https://brakar.no/reiseinformasjon/transportvedtekter/";
  }
};

const getCookiesUrl = (tenant: Tenant) => {
  switch (tenant) {
    case Tenant.Ruter:
      return "https://ruter.no/fa-hjelp/vilkar/personvern/ruter.no/";
    case Tenant.Brakar:
      return "https://brakar.no/personvern/informasjonskapsler/";
    case Tenant.Akt:
      return "https://www.akt.no/kundeservice/rettigheter-og-regler/personvern-i-agder-kollektivtrafikk-akt/informasjonskapsler/";
  }
};

const getPrivacyUrl = (tenant: Tenant) => {
  switch (tenant) {
    case Tenant.Ruter:
      return "https://ruter.no/fa-hjelp/vilkar/personvern/";
    case Tenant.Akt:
      return " https://www.akt.no/kundeservice/rettigheter-og-regler/personvern-i-agder-kollektivtrafikk-akt/personvern-i-akt-bedrift/";
    case Tenant.Brakar:
      return "https://brakar.no/personvern/";
  }
};

const calculateContactUs = (tenant: Tenant, authenticated: boolean): UrlType => {
  if (authenticated && tenant === Tenant.Ruter) {
    return {
      type: "Internal",
      url: "/kontaktoss",
    };
  }

  return {
    type: "External",
    url: getExternalContactUsUrl(tenant),
  };
};

const getTermsUrl = (tenant: Tenant, selectedCompany?: AuthCompany): UrlType | undefined => {
  const companyIdsWithAccessToNewApp = ["1228746", "2265618", "7695558"];

  switch (tenant) {
    case Tenant.Ruter: {
      if (selectedCompany && companyIdsWithAccessToNewApp.includes(selectedCompany.id)) {
        return {
          type: "External",
          url: "https://ruter.no/fa-hjelp/vilkar/personvern/avtale-ruter-profil/vilkar-bedriftsbillett/",
        };
      } else {
        return {
          type: "Internal",
          url: "/vilkar/ruterbedrift",
        };
      }
    }
    case Tenant.Akt:
      return {
        type: "External",
        url: "https://www.akt.no/kundeservice/rettigheter-og-regler/vilkar-for-bedriftsbillett-i-akt-billett-appen/",
      };
    default:
      return undefined;
  }
};


export const calculateUrls = (tenant: Tenant, authenticated: boolean, selectedCompany?: AuthCompany): Urls => {
  return {
    cookies: getCookiesUrl(tenant),
    privacy: getPrivacyUrl(tenant),
    travelTerms: getTravelTermsUrl(tenant),
    contactUs: calculateContactUs(tenant, authenticated),
    terms: getTermsUrl(tenant, selectedCompany),
  };
};

export const getValidUrls = (urls: Partial<Urls> = {}): Urls => ({
  ...calculateUrls(Tenant.Ruter, false),
  ...urls,
});

