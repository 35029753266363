import { LanguageResource } from "@ruter-as/web-components-and-tools";

interface UserLanguageResource {
  titleNew: string;
  titleEdit: string;
  globalRoles: string;
  globalUserAdmin: string;
  globalTravelCardAdmin: string;
  companyRoles: string;
  add: string;
  company: string;
  roles: string;
  noRoles: string;
  addCompanyRole: string;
  companySearch: string;
  companySearchPlaceholder: string;
  noResultFor: string;
  companyId: string;
  companyOrganizationNumber: string;
  companyName: string;
  companyAdminRole: string;
  subscriptionAdminRole: string;
  userAdminRole: string;
  freeTicketAdminRole: string;
  ticketCounterAdminRole: string;
  globalCustomerSupport: string;
  globalSchoolTicketAdmin: string
  schoolTicketAdminRole: string;
  noCompanyResult: string;
  changeRole: string;
  deleteRole: string;
  emailTakenMessage: string
}

const userLanguageResource: LanguageResource<UserLanguageResource> = {
  nb: {
    titleNew: "Ny bruker",
    titleEdit: "Endre bruker: $firstName $lastName ($userName)",
    globalRoles: "Globale roller (gjelder alle selskaper)",
    globalUserAdmin: "Bruker admin",
    globalTravelCardAdmin: "Reisekort admin",
    companyRoles: "Selskapsroller",
    add: "Legg til",
    company: "Selskapsnavn",
    roles: "Roller",
    noRoles: "En bruker må ha minst en rolle",
    addCompanyRole: "Legg til selskapsrolle",
    companySearch: "Søk etter selskap",
    companySearchPlaceholder: "Filtrer på navn, org.nummer eller id",
    noResultFor: 'Fant ingen selskap med id eller organisasjonsnummer lik "$query$"',
    companyId: "Selskapsid",
    companyOrganizationNumber: "Orginasasjonsnummer",
    companyName: "Selskapsnavn",
    companyAdminRole: "HR",
    subscriptionAdminRole: "Bedriftsavtale administrator",
    userAdminRole: "Bruker-administrator",
    freeTicketAdminRole: "Fribillett administrator",
    ticketCounterAdminRole: "Billettkjøper",
    globalCustomerSupport: "Kundestøtte",
    globalSchoolTicketAdmin: "Skoleadmin",
    schoolTicketAdminRole: "Skoleskyss",
    noCompanyResult: "Ingen bedrifter passer med søket",
    changeRole: "Endre rolle",
    deleteRole: "Slett rolle",
    emailTakenMessage: "Det eksisterer allerede en bruker med denne e-postadressen",
  },
  en: {
    titleNew: "New user",
    titleEdit: "Change user $firstName $lastName ($userName)",
    globalRoles: "Global roles",
    globalUserAdmin: "User admin",
    globalTravelCardAdmin: "Travel card admin",
    companyRoles: "Company roles",
    add: "Add",
    company: "Company",
    roles: "Roles",
    noRoles: "User must have minimum one role assigned",
    addCompanyRole: "Add company role",
    companySearch: "Find company",
    companySearchPlaceholder: "Search by id, organization number or name",
    noResultFor: 'Found no company with the following id or organization number: "$query$"',
    companyId: "Company id",
    companyOrganizationNumber: "Organization number",
    companyName: "Copmany name",
    companyAdminRole: "HR",
    subscriptionAdminRole: "Company agreement administrator",
    userAdminRole: "User administrator",
    freeTicketAdminRole: "Free-ticket agreement administrator",
    ticketCounterAdminRole: "Ticket counter administrator",
    globalCustomerSupport: "Customer support",
    schoolTicketAdminRole: "Student transport",
    noCompanyResult: "No companies matches your search",
    globalSchoolTicketAdmin: "School-ticket admin",
    changeRole: "Edit role",
    deleteRole: "Delete role",
    emailTakenMessage: "There already exists an user with this e-mail",
  },
};

export default userLanguageResource;
