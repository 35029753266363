import { LocaleText, LocaleTextJson, mapLocaleText } from "./localeText";
import { mapProduct, Product, ProductJson, ProductV2Json } from "./Product";


export interface ProductTemplateJson {
  id: number
  nrOfDaysValid: number | null
  name: LocaleTextJson
  nrOfZonesGetAll: number
  ticketType: string
  zoneType: string
  products: ProductJson[]
  description: LocaleTextJson | null
  nrOfMinutesValid: Array<{ nrOfMinutesValid: number, nrOfZones: number }> | null
  maxNrPassengersPrProduct: number
  maxTotalNrOfPassengers: number
  zoneTypeCommon: string
  ticketTypeCommon: string
  actionId: number
  mediaTypes: string[]
  tags?: string[] | null
}

export interface ProductTemplateV2Json {
  actionId: number
  description: LocaleTextJson | null
  id: string
  maxNrPassengersPrProduct: number | null
  maxTotalNrOfPassengers: number | null
  mediaTypes: string[]
  name: LocaleTextJson
  nrOfDaysValid: number | null
  nrOfMinutesValid: Array<{ nrOfMinutesValid: number, nrOfZones: number }> | null
  nrOfZonesGetAll: number | null
  products: ProductV2Json[]
  ticketType: string
  ticketTypeCommon: string
  zoneType: string
  zoneTypeCommon: string
  productIdV2: string
  updatedAt: string
  token: string
  productTemplateIdV1: number | null
  tags: string[] | null
}

export interface ProductTemplate {
  id: string
  nrOfDaysValid: number
  name: LocaleText
  products: Product[]
  version: "v1" | "v2"
  tags: string[]
}

export const mapProductTemplate = (json: ProductTemplateJson | ProductTemplateV2Json): ProductTemplate => {
  const mediaTypes = "token" in json ? [] : json.mediaTypes;
  const nrOfZonesGetAll = "token" in json ? null : json.nrOfZonesGetAll;
  const id = "token" in json ? json.token : json.id.toString();
  return {
    id,
    nrOfDaysValid: json.nrOfDaysValid || 0,
    name: mapLocaleText(json.name),
    products: json.products.map(product => mapProduct(product, mediaTypes, nrOfZonesGetAll)),
    version: "token" in json ? "v2" : "v1",
    tags: json.tags ? json.tags : [],
  };
};

export const mapProductTemplateArray = (json: Array<ProductTemplateJson | ProductTemplateV2Json>): ProductTemplate[] => json.map(mapProductTemplate);


export const getRuterProductTemplateReponse = (): ProductTemplateJson[] => [
  {
    "id": 11,
    "name": {
      "nb": "24-timersbillett*",
      "en": null,
    },
    "description": null,
    "nrOfZonesGetAll": 3,
    "nrOfDaysValid": 1,
    "nrOfMinutesValid": [],
    "maxNrPassengersPrProduct": 1,
    "maxTotalNrOfPassengers": 1,
    "zoneType": "ZoneToZone",
    "zoneTypeCommon": "ZONE_TO_ZONE",
    "ticketType": "MultipleJourneyTicket",
    "ticketTypeCommon": "MULTIPLE_JOURNEY",
    "actionId": 0,
    "mediaTypes": [
      "MOBILE_TICKET",
    ],
    "products": [
      {
        "id": 21226,
        "profileId": 1,
        "profileName": {
          "nb": "Voksen",
          "en": null,
        },
        "profileDescription": {
          "nb": "",
          "en": null,
        },
        "prices": [
          {
            "priceInclVAT": 127.00,
            "priceExclVAT": 113.39,
            "vat": 13.61,
            "vatPercent": 12.0,
            "nrOfZones": 1,
          },
          {
            "priceInclVAT": 209.00,
            "priceExclVAT": 186.61,
            "vat": 22.39,
            "vatPercent": 12.0,
            "nrOfZones": 2,
          },
          {
            "priceInclVAT": 291.00,
            "priceExclVAT": 259.82,
            "vat": 31.18,
            "vatPercent": 12.0,
            "nrOfZones": 3,
          },
        ],
      },
      {
        "id": 21236,
        "profileId": 4,
        "profileName": {
          "nb": "Honnør",
          "en": null,
        },
        "profileDescription": {
          "nb": "Fra 67 år og personer med norsk uføretrygd.",
          "en": null,
        },
        "prices": [
          {
            "priceInclVAT": 64.00,
            "priceExclVAT": 57.14,
            "vat": 6.86,
            "vatPercent": 12.0,
            "nrOfZones": 1,
          },
          {
            "priceInclVAT": 105.00,
            "priceExclVAT": 93.75,
            "vat": 11.25,
            "vatPercent": 12.0,
            "nrOfZones": 2,
          },
          {
            "priceInclVAT": 146.00,
            "priceExclVAT": 130.36,
            "vat": 15.64,
            "vatPercent": 12.0,
            "nrOfZones": 3,
          },
        ],
      },
      {
        "id": 21231,
        "profileId": 2,
        "profileName": {
          "nb": "Barn",
          "en": null,
        },
        "profileDescription": {
          "nb": "Under 18 år. Barn under 6 år reiser gratis.",
          "en": null,
        },
        "prices": [
          {
            "priceInclVAT": 64.00,
            "priceExclVAT": 57.14,
            "vat": 6.86,
            "vatPercent": 12.0,
            "nrOfZones": 1,
          },
          {
            "priceInclVAT": 105.00,
            "priceExclVAT": 93.75,
            "vat": 11.25,
            "vatPercent": 12.0,
            "nrOfZones": 2,
          },
          {
            "priceInclVAT": 146.00,
            "priceExclVAT": 130.36,
            "vat": 15.64,
            "vatPercent": 12.0,
            "nrOfZones": 3,
          },
        ],
      },
    ],
  },
  {
    "id": 4,
    "name": {
      "nb": "30-dagersbillett",
      "en": null,
    },
    "description": null,
    "nrOfZonesGetAll": 3,
    "nrOfDaysValid": 30,
    "nrOfMinutesValid": [],
    "maxNrPassengersPrProduct": 1,
    "maxTotalNrOfPassengers": 1,
    "zoneType": "ZoneToZone",
    "zoneTypeCommon": "ZONE_TO_ZONE",
    "ticketType": "MultipleJourneyTicket",
    "ticketTypeCommon": "MULTIPLE_JOURNEY",
    "actionId": 0,
    "mediaTypes": [
      "MOBILE_TICKET",
    ],
    "products": [
      {
        "id": 21076,
        "profileId": 2,
        "profileName": {
          "nb": "Barn",
          "en": null,
        },
        "profileDescription": {
          "nb": "Under 18 år. Barn under 6 år reiser gratis.",
          "en": null,
        },
        "prices": [
          {
            "priceInclVAT": 314.00,
            "priceExclVAT": 280.36,
            "vat": 33.64,
            "vatPercent": 12.0,
            "nrOfZones": 1,
          },
          {
            "priceInclVAT": 419.00,
            "priceExclVAT": 374.11,
            "vat": 44.89,
            "vatPercent": 12.0,
            "nrOfZones": 2,
          },
          {
            "priceInclVAT": 524.00,
            "priceExclVAT": 467.86,
            "vat": 56.14,
            "vatPercent": 12.0,
            "nrOfZones": 3,
          },
        ],
      },
      {
        "id": 21086,
        "profileId": 4,
        "profileName": {
          "nb": "Honnør",
          "en": null,
        },
        "profileDescription": {
          "nb": "Fra 67 år og personer med norsk uføretrygd.",
          "en": null,
        },
        "prices": [
          {
            "priceInclVAT": 449.00,
            "priceExclVAT": 400.89,
            "vat": 48.11,
            "vatPercent": 12.0,
            "nrOfZones": 1,
          },
          {
            "priceInclVAT": 796.00,
            "priceExclVAT": 710.71,
            "vat": 85.29,
            "vatPercent": 12.0,
            "nrOfZones": 2,
          },
          {
            "priceInclVAT": 1142.00,
            "priceExclVAT": 1019.64,
            "vat": 122.36,
            "vatPercent": 12.0,
            "nrOfZones": 3,
          },
        ],
      },
      {
        "id": 21081,
        "profileId": 3,
        "profileName": {
          "nb": "Student",
          "en": null,
        },
        "profileDescription": {
          "nb": "Heltidsstudenter under 30 år.",
          "en": null,
        },
        "prices": [
          {
            "priceInclVAT": 537.00,
            "priceExclVAT": 479.46,
            "vat": 57.54,
            "vatPercent": 12.0,
            "nrOfZones": 1,
          },
          {
            "priceInclVAT": 954.00,
            "priceExclVAT": 851.79,
            "vat": 102.21,
            "vatPercent": 12.0,
            "nrOfZones": 2,
          },
          {
            "priceInclVAT": 1371.00,
            "priceExclVAT": 1224.11,
            "vat": 146.89,
            "vatPercent": 12.0,
            "nrOfZones": 3,
          },
        ],
      },
      {
        "id": 21051,
        "profileId": 1,
        "profileName": {
          "nb": "Voksen",
          "en": null,
        },
        "profileDescription": {
          "nb": "",
          "en": null,
        },
        "prices": [
          {
            "priceInclVAT": 897.00,
            "priceExclVAT": 800.89,
            "vat": 96.11,
            "vatPercent": 12.0,
            "nrOfZones": 1,
          },
          {
            "priceInclVAT": 1589.00,
            "priceExclVAT": 1418.75,
            "vat": 170.25,
            "vatPercent": 12.0,
            "nrOfZones": 2,
          },
          {
            "priceInclVAT": 2285.00,
            "priceExclVAT": 2040.18,
            "vat": 244.82,
            "vatPercent": 12.0,
            "nrOfZones": 3,
          },
        ],
      },
      {
        "id": 21091,
        "profileId": 32,
        "profileName": {
          "nb": "Ungdom",
          "en": null,
        },
        "profileDescription": {
          "nb": "Under 20 år.",
          "en": null,
        },
        "prices": [
          {
            "priceInclVAT": 314.00,
            "priceExclVAT": 280.36,
            "vat": 33.64,
            "vatPercent": 12.0,
            "nrOfZones": 1,
          },
          {
            "priceInclVAT": 419.00,
            "priceExclVAT": 374.11,
            "vat": 44.89,
            "vatPercent": 12.0,
            "nrOfZones": 2,
          },
          {
            "priceInclVAT": 524.00,
            "priceExclVAT": 467.86,
            "vat": 56.14,
            "vatPercent": 12.0,
            "nrOfZones": 3,
          },
        ],
      },
    ],
  },
  {
    "id": 10,
    "name": {
      "nb": "7-dagersbillett*",
      "en": null,
    },
    "description": null,
    "nrOfZonesGetAll": 3,
    "nrOfDaysValid": 7,
    "nrOfMinutesValid": [],
    "maxNrPassengersPrProduct": 1,
    "maxTotalNrOfPassengers": 1,
    "zoneType": "ZoneToZone",
    "zoneTypeCommon": "ZONE_TO_ZONE",
    "ticketType": "MultipleJourneyTicket",
    "ticketTypeCommon": "MULTIPLE_JOURNEY",
    "actionId": 0,
    "mediaTypes": [
      "MOBILE_TICKET",
    ],
    "products": [
      {
        "id": 21201,
        "profileId": 1,
        "profileName": {
          "nb": "Voksen",
          "en": null,
        },
        "profileDescription": {
          "nb": "",
          "en": null,
        },
        "prices": [
          {
            "priceInclVAT": 352.00,
            "priceExclVAT": 314.29,
            "vat": 37.71,
            "vatPercent": 12.0,
            "nrOfZones": 1,
          },
          {
            "priceInclVAT": 615.00,
            "priceExclVAT": 549.11,
            "vat": 65.89,
            "vatPercent": 12.0,
            "nrOfZones": 2,
          },
          {
            "priceInclVAT": 852.00,
            "priceExclVAT": 760.71,
            "vat": 91.29,
            "vatPercent": 12.0,
            "nrOfZones": 3,
          },
        ],
      },
      {
        "id": 21206,
        "profileId": 2,
        "profileName": {
          "nb": "Barn",
          "en": null,
        },
        "profileDescription": {
          "nb": "Under 18 år. Barn under 6 år reiser gratis.",
          "en": null,
        },
        "prices": [
          {
            "priceInclVAT": 177.00,
            "priceExclVAT": 158.04,
            "vat": 18.96,
            "vatPercent": 12.0,
            "nrOfZones": 1,
          },
          {
            "priceInclVAT": 307.00,
            "priceExclVAT": 274.11,
            "vat": 32.89,
            "vatPercent": 12.0,
            "nrOfZones": 2,
          },
          {
            "priceInclVAT": 426.00,
            "priceExclVAT": 380.36,
            "vat": 45.64,
            "vatPercent": 12.0,
            "nrOfZones": 3,
          },
        ],
      },
      {
        "id": 21211,
        "profileId": 4,
        "profileName": {
          "nb": "Honnør",
          "en": null,
        },
        "profileDescription": {
          "nb": "Fra 67 år og personer med norsk uføretrygd.",
          "en": null,
        },
        "prices": [
          {
            "priceInclVAT": 177.00,
            "priceExclVAT": 158.04,
            "vat": 18.96,
            "vatPercent": 12.0,
            "nrOfZones": 1,
          },
          {
            "priceInclVAT": 307.00,
            "priceExclVAT": 274.11,
            "vat": 32.89,
            "vatPercent": 12.0,
            "nrOfZones": 2,
          },
          {
            "priceInclVAT": 426.00,
            "priceExclVAT": 380.36,
            "vat": 45.64,
            "vatPercent": 12.0,
            "nrOfZones": 3,
          },
        ],
      },
      {
        "id": 21216,
        "profileId": 32,
        "profileName": {
          "nb": "Ungdom",
          "en": null,
        },
        "profileDescription": {
          "nb": "Under 20 år.",
          "en": null,
        },
        "prices": [
          {
            "priceInclVAT": 177.00,
            "priceExclVAT": 158.04,
            "vat": 18.96,
            "vatPercent": 12.0,
            "nrOfZones": 1,
          },
          {
            "priceInclVAT": 307.00,
            "priceExclVAT": 274.11,
            "vat": 32.89,
            "vatPercent": 12.0,
            "nrOfZones": 2,
          },
          {
            "priceInclVAT": 426.00,
            "priceExclVAT": 380.36,
            "vat": 45.64,
            "vatPercent": 12.0,
            "nrOfZones": 3,
          },
        ],
      },
    ],
  },
  {
    "id": 2,
    "name": {
      "nb": "365-dagersbillett*",
      "en": null,
    },
    "description": null,
    "nrOfZonesGetAll": 3,
    "nrOfDaysValid": 365,
    "nrOfMinutesValid": [],
    "maxNrPassengersPrProduct": 1,
    "maxTotalNrOfPassengers": 1,
    "zoneType": "ZoneToZone",
    "zoneTypeCommon": "ZONE_TO_ZONE",
    "ticketType": "MultipleJourneyTicket",
    "ticketTypeCommon": "MULTIPLE_JOURNEY",
    "actionId": 0,
    "mediaTypes": [
      "MOBILE_TICKET",
    ],
    "products": [
      {
        "id": 21001,
        "profileId": 1,
        "profileName": {
          "nb": "Voksen",
          "en": null,
        },
        "profileDescription": {
          "nb": "",
          "en": null,
        },
        "prices": [
          {
            "priceInclVAT": 8966.00,
            "priceExclVAT": 8005.36,
            "vat": 960.64,
            "vatPercent": 12.0,
            "nrOfZones": 1,
          },
          {
            "priceInclVAT": 15894.00,
            "priceExclVAT": 14191.07,
            "vat": 1702.93,
            "vatPercent": 12.0,
            "nrOfZones": 2,
          },
          {
            "priceInclVAT": 22845.00,
            "priceExclVAT": 20397.32,
            "vat": 2447.68,
            "vatPercent": 12.0,
            "nrOfZones": 3,
          },
        ],
      },
      {
        "id": 21011,
        "profileId": 4,
        "profileName": {
          "nb": "Honnør",
          "en": null,
        },
        "profileDescription": {
          "nb": "Fra 67 år og personer med norsk uføretrygd.",
          "en": null,
        },
        "prices": [
          {
            "priceInclVAT": 4483.00,
            "priceExclVAT": 4002.68,
            "vat": 480.32,
            "vatPercent": 12.0,
            "nrOfZones": 1,
          },
          {
            "priceInclVAT": 7947.00,
            "priceExclVAT": 7095.54,
            "vat": 851.46,
            "vatPercent": 12.0,
            "nrOfZones": 2,
          },
          {
            "priceInclVAT": 11422.00,
            "priceExclVAT": 10198.21,
            "vat": 1223.79,
            "vatPercent": 12.0,
            "nrOfZones": 3,
          },
        ],
      },
    ],
  },
  {
    "id": 18,
    "name": {
      "nb": "Enkeltbillett*",
      "en": null,
    },
    "description": null,
    "nrOfZonesGetAll": 5,
    "nrOfDaysValid": null,
    "nrOfMinutesValid": [
      {
        "nrOfZones": 1,
        "nrOfMinutesValid": 60,
      },
      {
        "nrOfZones": 2,
        "nrOfMinutesValid": 90,
      },
      {
        "nrOfZones": 3,
        "nrOfMinutesValid": 120,
      },
      {
        "nrOfZones": 4,
        "nrOfMinutesValid": 150,
      },
      {
        "nrOfZones": 5,
        "nrOfMinutesValid": 180,
      },
    ],
    "maxNrPassengersPrProduct": 25,
    "maxTotalNrOfPassengers": 25,
    "zoneType": "ZoneToZone",
    "zoneTypeCommon": "ZONE_TO_ZONE",
    "ticketType": "SingleJourneyTicket",
    "ticketTypeCommon": "SINGLE_JOURNEY",
    "actionId": 0,
    "mediaTypes": [
      "MOBILE_TICKET",
    ],
    "products": [
      {
        "id": 21526,
        "profileId": 1,
        "profileName": {
          "nb": "Voksen",
          "en": null,
        },
        "profileDescription": {
          "nb": "",
          "en": null,
        },
        "prices": [
          {
            "priceInclVAT": 42.00,
            "priceExclVAT": 37.50,
            "vat": 4.50,
            "vatPercent": 12.0,
            "nrOfZones": 1,
          },
          {
            "priceInclVAT": 69.00,
            "priceExclVAT": 61.61,
            "vat": 7.39,
            "vatPercent": 12.0,
            "nrOfZones": 2,
          },
          {
            "priceInclVAT": 97.00,
            "priceExclVAT": 86.61,
            "vat": 10.39,
            "vatPercent": 12.0,
            "nrOfZones": 3,
          },
          {
            "priceInclVAT": 124.00,
            "priceExclVAT": 110.71,
            "vat": 13.29,
            "vatPercent": 12.0,
            "nrOfZones": 4,
          },
          {
            "priceInclVAT": 151.00,
            "priceExclVAT": 134.82,
            "vat": 16.18,
            "vatPercent": 12.0,
            "nrOfZones": 5,
          },
        ],
      },
      {
        "id": 21531,
        "profileId": 2,
        "profileName": {
          "nb": "Barn",
          "en": null,
        },
        "profileDescription": {
          "nb": "Under 18 år. Barn under 6 år reiser gratis.",
          "en": null,
        },
        "prices": [
          {
            "priceInclVAT": 17.00,
            "priceExclVAT": 15.18,
            "vat": 1.82,
            "vatPercent": 12.0,
            "nrOfZones": 1,
          },
          {
            "priceInclVAT": 27.00,
            "priceExclVAT": 24.11,
            "vat": 2.89,
            "vatPercent": 12.0,
            "nrOfZones": 2,
          },
          {
            "priceInclVAT": 37.00,
            "priceExclVAT": 33.04,
            "vat": 3.96,
            "vatPercent": 12.0,
            "nrOfZones": 3,
          },
          {
            "priceInclVAT": 48.00,
            "priceExclVAT": 42.86,
            "vat": 5.14,
            "vatPercent": 12.0,
            "nrOfZones": 4,
          },
          {
            "priceInclVAT": 59.00,
            "priceExclVAT": 52.68,
            "vat": 6.32,
            "vatPercent": 12.0,
            "nrOfZones": 5,
          },
        ],
      },
      {
        "id": 21536,
        "profileId": 4,
        "profileName": {
          "nb": "Honnør",
          "en": null,
        },
        "profileDescription": {
          "nb": "Fra 67 år og personer med norsk uføretrygd.",
          "en": null,
        },
        "prices": [
          {
            "priceInclVAT": 21.00,
            "priceExclVAT": 18.75,
            "vat": 2.25,
            "vatPercent": 12.0,
            "nrOfZones": 1,
          },
          {
            "priceInclVAT": 34.00,
            "priceExclVAT": 30.36,
            "vat": 3.64,
            "vatPercent": 12.0,
            "nrOfZones": 2,
          },
          {
            "priceInclVAT": 47.00,
            "priceExclVAT": 41.96,
            "vat": 5.04,
            "vatPercent": 12.0,
            "nrOfZones": 3,
          },
          {
            "priceInclVAT": 60.00,
            "priceExclVAT": 53.57,
            "vat": 6.43,
            "vatPercent": 12.0,
            "nrOfZones": 4,
          },
          {
            "priceInclVAT": 74.00,
            "priceExclVAT": 66.07,
            "vat": 7.93,
            "vatPercent": 12.0,
            "nrOfZones": 5,
          },
        ],
      },
    ],
  },
  {
    "id": 40,
    "name": {
      "nb": "Rufusbillett",
      "en": null,
    },
    "description": null,
    "nrOfZonesGetAll": 3,
    "nrOfDaysValid": 1,
    "nrOfMinutesValid": [],
    "maxNrPassengersPrProduct": 1,
    "maxTotalNrOfPassengers": 1,
    "zoneType": "ZoneToZone",
    "zoneTypeCommon": "ZONE_TO_ZONE",
    "ticketType": "MultipleJourneyTicket",
    "ticketTypeCommon": "MULTIPLE_JOURNEY",
    "actionId": 1,
    "mediaTypes": [
      "TRAVEL_CARD_ULTRALIGHT",
    ],
    "products": [
      {
        "id": 22091,
        "profileId": 2,
        "profileName": {
          "nb": "Gruppe",
          "en": null,
        },
        "profileDescription": {
          "nb": "Inntil 15 personer, hvorav 4 voksne.",
          "en": null,
        },
        "prices": [
          {
            "priceInclVAT": 184.00,
            "priceExclVAT": 164.29,
            "vat": 19.71,
            "vatPercent": 12.0,
            "nrOfZones": 1,
          },
          {
            "priceInclVAT": 369.00,
            "priceExclVAT": 329.46,
            "vat": 39.54,
            "vatPercent": 12.0,
            "nrOfZones": 2,
          },
          {
            "priceInclVAT": 553.00,
            "priceExclVAT": 493.75,
            "vat": 59.25,
            "vatPercent": 12.0,
            "nrOfZones": 3,
          },
        ],
      },
    ],
  },
];