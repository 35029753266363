import { AuthenticationService, OrderTicketWizard, ticketCounterApi } from "@ruter-as/billettluke-frontend";
import { apiClient } from "@ruter-as/web-components-and-tools";
import { Container } from "@ruter-ds/rds-components";
import * as sentry from "@sentry/react";
import React, { useEffect, useState } from "react";
import { useAuthContextAuthenticated, useValidTicketCounterService } from "../../../AuthContext";
import "./TicketCounterOrder.scss";

const TicketCounterOrder: React.FC = () => {
  const authContext = useAuthContextAuthenticated();
  const ticketCounterService = useValidTicketCounterService();
  const companyId = authContext.userData.selectedCompany.id;
  const { visitAddress } = ticketCounterService;
  const [allowTicketAdminsToUpdateInvoiceReferences, setAllowTicketAdminsToUpdateInvoiceReferences] = useState<boolean>(false);
  const [invoiceReferenceIsMandatory, setInvoiceReferenceIsMandatory] = useState<boolean>(false);

  useEffect(() => {
    if (companyId) {
      const fetch = async () => {
        const settingsResponse = await apiClient.request(ticketCounterApi.company.getSettings(companyId));
        if (settingsResponse.type === "success") {
          setAllowTicketAdminsToUpdateInvoiceReferences(settingsResponse.result.allowTicketAdminsToUpdateInvoiceReferences);
          setInvoiceReferenceIsMandatory(settingsResponse.result.invoiceReferenceIsMandatory);
        } else {
          setAllowTicketAdminsToUpdateInvoiceReferences(() => {
            throw settingsResponse.error;
          });
        }
      };

      fetch();
    }
  }, [companyId]);

  if (!ticketCounterService.isValid) {
    throw new Error("Missing valid ticket counter agreement");
  }

  if (!authContext) {
    throw new Error("AuthContext can not be null");
  }

  if (!companyId) {
    throw new Error("companyId must exist");
  }

  const authService: AuthenticationService = {
    getAccessToken: async () => {
      const token = await authContext.getAccessToken();
      if (!token) {
        throw new Error("could not find token");
      }
      return token;
    },
  };

  const { errorLink } = ticketCounterService;

  return (
    <Container width="s" className="components-ticketcounter-order">
      <OrderTicketWizard
        consumer="company"
        agreementNumber={ticketCounterService.agreementNumber}
        tenant={ticketCounterService.ticketCounterTenant}
        authService={authService}
        errorLink={errorLink}
        companyId={companyId}
        quickCheckoutEnabled={true}
        blacklistedProducts={ticketCounterService.blackListedProducts}
        allowCreateInvoiceReference={allowTicketAdminsToUpdateInvoiceReferences}
        deliveryAddress={visitAddress ? {
          addressLine1: visitAddress.line1,
          addressLine2: visitAddress.line2,
          zipCode: visitAddress.postCode,
          area: visitAddress.city,
        } : undefined}
        reportError={(error : any) => {sentry.captureException(error);}}
        invoiceReferenceIsMandatory={invoiceReferenceIsMandatory}
      />
    </Container>
  );
};

export default TicketCounterOrder;
