import { LanguageResource } from "@ruter-as/web-components-and-tools";

interface TicketCounterSettingsLanguageResource {
  title: string;
  invoiceRefTitle: string;
  createInvButton: string;
  deleteButton: string;
  actions: string
  createInv: string
  save: string
  cancel: string
  invoiceSettings: string
  allowTicketAdminToUpdateInvoiceReferencesText: string
  invoiceReferenceIsMandatory: string
  invoices: string
}

const ticketCounterSettingsLanguageResource : LanguageResource<TicketCounterSettingsLanguageResource> = {
  nb: {
    title: "Innstillinger",
    invoiceRefTitle: "Fakturareferanse",
    createInvButton: "Ny fakturareferanse",
    deleteButton: "Slett",
    actions: "Handlinger",
    createInv: "Opprett ny fakturareferanse",
    cancel: "Avbryt",
    save: "Lagre",
    invoiceSettings: "Instillinger for fakturareferanse",
    allowTicketAdminToUpdateInvoiceReferencesText: "Billettkjøpere kan opprette nye fakturareferanser",
    invoiceReferenceIsMandatory: "Fakturareferanse er obligatorisk",
    invoices: "Fakturareferanser",
  }, en: {
    title: "Ticket info",
    invoiceRefTitle: "Invoice references",
    createInvButton: "New invoice reference",
    deleteButton: "Delete",
    actions: "Actions",
    createInv: "Create invoice reference",
    cancel: "Cancel",
    save: "Save",
    invoiceSettings: "Settings for invoice references ",
    allowTicketAdminToUpdateInvoiceReferencesText: "Ticket buyers are allowed to create new invoice references",
    invoiceReferenceIsMandatory: "Invoice reference is mandatory",
    invoices: "Invoices",
  },
};

export default ticketCounterSettingsLanguageResource;
