/* eslint-disable jsx-a11y/control-has-associated-label */
import { MediaInfoMobile, ProductTemplate, SearchResult, ticketCounterApi, TicketCounterDelivery, TicketCounterOrder, TicketCounterOrderAndDelivery, TicketCounterTicket, TicketCounterZone, TravelMoney } from "@ruter-as/billettluke-frontend";
import { apiClient, Button, ButtonGroup, Container, csvTools, formatter, IconButton, Message, Modal, ProgressRadial, SearchInput, Table, TablePagination, useLanguageContext, useLanguageResource } from "@ruter-as/web-components-and-tools";
import * as sentry from "@sentry/react";
import React, { ChangeEvent, MouseEvent, useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useValidTicketCounterService } from "src/AuthContext";
import usePaginationAndQuery from "src/common/usePaginationAndQuery";
import { ButtonLink } from "src/components/common/buttons";
import PickupCodeColumn from "src/components/common/Table/PhoneColumn/PickupCodeColumn";
import { ticketZonesLanguageResource } from "src/components/common/text/ticketZones/lang-resource";
import successAlert, { failAlert } from "../../../common/toastr";
import PhoneColumn from "../../common/Table/PhoneColumn/PhoneColumn";
import PriceColumn from "../../common/Table/PriceColumn/PriceColumn";
import StatusColumn from "../../common/Table/StatusColumn/StatusColumn";
import YesNoColumn from "../../common/Table/YesNoColumn/YesNoColumn";
import ActionButtonsColumn from "./ActionButtonsColumn";
import orderDetailsLanguageResource from "./lang-resource";
import "./orderDetails.scss";

let fetchOrdersAndTicketsTimout: NodeJS.Timeout;

const OrderDetails: React.FC = () => {
  const ticketCounterService = useValidTicketCounterService();
  const componentIsMounted = useRef(false);
  const [order, setOrder] = useState<TicketCounterOrder | undefined>(undefined);
  const [ticketsResult, setTicketsResult] = useState<SearchResult<TicketCounterOrderAndDelivery> | undefined>(undefined);
  const [productTemplates, setProductTemplates] = useState<ProductTemplate[] | undefined>(undefined);
  const [zones, setZones] = useState<TicketCounterZone[] | undefined>(undefined);
  const [resendingTicketId, setResendingTicketId] = useState<undefined | string>(undefined);
  const [cancelTicketId, setCancelTicketId] = useState<undefined | string>(undefined);
  const [cancelTicketFailed, setCancelTicketFailed] = useState(false);
  const [cancelTicketInProgress, setCancelTicketInProgress] = useState(false);
  const [refundTicketId, setRefundTicketId] = useState<undefined | string>();
  const [refundTicketInProgress, setRefundTicketInProgress] = useState(false);
  const [refundFailed, setRefundFailed] = useState(false);
  const [refundedTickets, setRefundedTickets] = useState<SearchResult<TicketCounterOrderAndDelivery>>();
  const [showDownloadError, setDownloadError] = useState(false);

  const { throttledQuery, query, setQuery, pagination } = usePaginationAndQuery();
  const { page, pageSize } = pagination;
  const isTicketOrder = order?.productType === "TICKET";
  const isRuterMailOrder = ticketCounterService.hasMailDelivery && isTicketOrder && (order?.mediaType === "TRAVEL_CARD_ULTRALIGHT" || order?.mediaType === "TRAVEL_CARD");
  const { selectedLanguage } = useLanguageContext();
  const zonesLang = useLanguageResource(ticketZonesLanguageResource);

  const loading = !order || !productTemplates;

  const { id: orderId } = useParams();

  const language = useLanguageResource(orderDetailsLanguageResource);

  const orderIsComplete = ticketsResult?.totalEntries === order?.ticketCount;
  const deliveries = ticketsResult?.matches.map(x => x.delivery);

  if (!orderId) {
    throw new Error(`${orderId} is not a valid order id`);
  }

  const fetchOrdersAndTickets = useCallback(async () => {
    if (fetchOrdersAndTicketsTimout) {
      clearTimeout(fetchOrdersAndTicketsTimout);
    }
    const [ticketsResponse, orderResponse] = await Promise.all([
      apiClient.request(ticketCounterApi.ticket.getByOrderId(orderId, throttledQuery, page, pageSize)),
      apiClient.request(ticketCounterApi.order.getById(orderId)),
    ]);

    if (ticketsResponse.error) {
      setOrder(() => { throw ticketsResponse.error; });
    } else if (orderResponse.error) {
      setOrder(() => { throw orderResponse.error; });
    } else {
      setTicketsResult(ticketsResponse.result);
      setOrder(orderResponse.result);

      if (ticketsResponse.result.totalEntries !== orderResponse.result.ticketCount && componentIsMounted.current) {
        fetchOrdersAndTicketsTimout = setTimeout(() => fetchOrdersAndTickets(), 1000);
      }
    }
  }, [page, pageSize, orderId, throttledQuery]);

  const fetchRefundedTickets = useCallback(async () => {
    if (ticketCounterService.allowedRefund) {
      const response = await apiClient.request(ticketCounterApi.invoice.getRefundedTickets(orderId));
      if (response.type === "success") {
        setRefundedTickets(response.result);
      } else {
        setRefundedTickets(() => { throw response.error; });
      }
    }
  }, [orderId, ticketCounterService.allowedRefund]);


  useEffect(() => {
    componentIsMounted.current = true;
    return () => {
      clearTimeout(fetchOrdersAndTicketsTimout);
      componentIsMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const fetch = async () => {
      const [productTemplateResponse, zonesResponse] = await Promise.all([
        apiClient.request(ticketCounterApi.products.getAll(true)),
        apiClient.request(ticketCounterApi.zones.getAll()),
      ]);
      if (productTemplateResponse.error) {
        setProductTemplates(() => { throw productTemplateResponse.error; });
      } else if (zonesResponse.error) {
        setProductTemplates(() => { throw zonesResponse.error; });
      } else {
        setProductTemplates(productTemplateResponse.result);
        setZones(zonesResponse.result);
      }
    };
    fetch();
    fetchRefundedTickets();
    fetchOrdersAndTickets();
  }, [orderId, fetchRefundedTickets, fetchOrdersAndTickets]);

  if (loading) {
    return null;
  }

  const cancelTicket = async (): Promise<void> => {
    setCancelTicketFailed(false);
    setCancelTicketInProgress(true);

    if (!cancelTicketId) {
      throw new Error("cancelTicketId is undefined");
    }

    const response = await apiClient.request(ticketCounterApi.ticket.cancelPending(cancelTicketId));
    if (response.type === "success") {
      setCancelTicketInProgress(false);
      fetchOrdersAndTickets();
      successAlert(language.ticketCancelled);
      setCancelTicketId(undefined);
    } else {
      setCancelTicketFailed(true);
      setCancelTicketInProgress(false);
      sentry.captureException(response.error);
    }

  };

  const getMedium = (): string => {
    switch (order.mediaType) {
      case "TRAVEL_CARD_ULTRALIGHT": return language.IMPULSCARD;
      case "MOBILE_TICKET": return language.MOBILETICKET;
      case "TRAVEL_CARD": return language.TRAVELCARD;
    }
  };

  const getProduct = () => {
    if (!order) {
      throw new Error("order can not be null");
    }

    const { productTemplateId } = order;

    const productTemplate = productTemplates.find((x) => x.id === productTemplateId);

    if (productTemplate) {
      if (selectedLanguage === "en") {
        return productTemplate.name.en;
      }
      return productTemplate.name.nb;
    }
    if (order.productName) {
      return order.productName;
    }

    return "";

  };

  const getProfile = () => {
    if (!order) {
      throw new Error("order can not be null");
    }

    return order.profileName;
  };

  const formatDate = () => {
    if (!order) {
      throw new Error("order can not be null");
    }

    return formatter.date.toShortDateString(order.purchaseDate);
  };

  const cancelTicketButtonClick = (event: MouseEvent<HTMLButtonElement>, ticketId: string) => {
    event.stopPropagation();
    setCancelTicketId(ticketId);
  };

  const resendCode = async (event: MouseEvent<HTMLButtonElement>, ticketId: string): Promise<void> => {
    event.stopPropagation();
    setResendingTicketId(ticketId);
    const response = await apiClient.request(ticketCounterApi.ticket.resendPickupCode(ticketId));
    if (response.type === "success") {
      successAlert(language.resendPickupCodeSuccess);
    } else {
      failAlert(language.resendPickupCodeFail);
      sentry.captureException(response.error);
    }
    setResendingTicketId(undefined);
  };

  const renderTicketCount = () => {
    if (!order) {
      throw new Error("order can not be null");
    }

    if (!isTicketOrder) {
      throw new Error("order is not a ticket order");
    }

    if (isRuterMailOrder) {
      return order.passengerCount;
    }
    if (order.ticketCount === ticketsResult?.totalEntries) {
      return order.ticketCount;
    }
    return (
      <div className="ticket-count">
        <span data-test-id="ticket-count-label" className="ticket-count-label" style={{ marginRight: "0.5rem" }}>
          {language.ticketCountStatus((ticketsResult?.totalEntries || 0).toString(), order.ticketCount.toString())}
        </span>
        <ProgressRadial type="Detirmate" min={0} max={order.ticketCount} value={ticketsResult?.totalEntries || 0} />
      </div>
    );
  };

  const renderTravelMoney = (travelMoney: TravelMoney) => {
    return (
      <tr key={travelMoney.id}>
        <td>{travelMoney.id}</td>
        <PriceColumn price={travelMoney.totalPrice || 0} data-label={language.price} />
        <td>{travelMoney.mediaInfo.type === "TRAVEL_CARD" ? travelMoney.mediaInfo.travelCardNumber : ""}</td>
        <StatusColumn status={travelMoney.status} data-label={language.status} />
      </tr>
    );
  };

  const renderTicket = (ticket: TicketCounterTicket) => {
    const mediaInfo = ticket.mediaInfo;
    const resendingInProgress = resendingTicketId === ticket.id;
    return (
      <tr key={ticket.id}>
        <td>{ticket.id}</td>
        <PriceColumn price={ticket.productPrice || 0} data-label={language.price} showDecimals/>
        {mediaInfo.type === "MOBILE_TICKET" && (
          <>
            <PickupCodeColumn data-label={language.pickUpCode} pickupCode={mediaInfo.pickupCode} />
            <PhoneColumn phone={mediaInfo.phone} phoneCountryCode={mediaInfo.phoneCountryCode} data-label={language.phone} />
            <YesNoColumn yes={mediaInfo.downloadedToPhone} data-label={language.downloaded} />
          </>
        )}
        {mediaInfo.type === "TRAVEL_CARD" && (
          <td>{mediaInfo.travelCardNumber}</td>
        )}
        <StatusColumn status={ticket.status} data-label={language.status} />
        <ActionButtonsColumn ticket={ticket} data-label="" >
          <ButtonGroup className="group">
            {mediaInfo.type === "MOBILE_TICKET" && (
              <IconButton
                variant="CellPhoneArrowRightIcon"
                loading={resendingInProgress}
                onClick={(event) => { resendCode(event, ticket.id); }}
                data-test-id="resend-button"
                title={language.resendCode}
                aria-label={language.resendCode}
                disabled={!mediaInfo.phone}
              />
            )}
            {ticketCounterService.allowedCancellation && (
              <IconButton
                variant="CrossIcon"
                data-test-id="cancel-ticket-button"
                title={language.cancelTicket}
                onClick={(event) => cancelTicketButtonClick(event, ticket.id)}
                aria-label={language.cancelTicket}
              />
            )}
            {ticketCounterService.allowedRefund && (
              <IconButton
                data-test-id="refund-ticket-button"
                variant="ArrowBackIcon"
                aria-label={language.refund}
                title={language.cancelTicket}
                onClick={() => setRefundTicketId(ticket.id)}
                disabled={refundedTickets?.matches.some(x => x.delivery.id === ticket.id)}

              />
            )}
          </ButtonGroup>
        </ActionButtonsColumn>
      </tr>
    );
  };

  const renderRow = (delivery: TicketCounterDelivery) => {
    switch (delivery.type) {
      case "TICKET":
        return renderTicket(delivery);
      case "TRAVELMONEY":
        return renderTravelMoney(delivery);
    }
  };

  const download = async () => {
    setDownloadError(false);
    const response = await apiClient.request(ticketCounterApi.ticket.getByOrderId(orderId, throttledQuery, 1, 10000));

    if (response.error) {
      throw response.error;
    }

    const tickets = response.result.matches.map(ordersAndTickets => ordersAndTickets.delivery).filter(x => x.type === "TICKET") as TicketCounterTicket[];
    const mediaInfos = tickets.map(ticket => ticket.mediaInfo);
    const filteredMediaInfos = mediaInfos.filter(mediaInfo => mediaInfo.type === "MOBILE_TICKET") as MediaInfoMobile[];
    const pickupCodes = filteredMediaInfos.filter((mediaInfo) => !mediaInfo.downloadedToPhone).map((mediaInfo) => mediaInfo.pickupCode);

    if (pickupCodes.length === 0) {
      setDownloadError(true);
      return;
    }

    const csvModel = pickupCodes.map((pickupCode) => [pickupCode || ""]);
    const headers = [language.pickUpCode];
    const dateAsString = formatter.date.toShortDateString(new Date());

    const fileName = `Ubrukte hentekoder per ${dateAsString}.csv`;

    csvTools.downloadAsCsv(fileName, [headers, ...csvModel]);
  };

  const refundTicket = async (id: string) => {
    setRefundTicketInProgress(true);
    setRefundFailed(false);

    const response = await apiClient.request(ticketCounterApi.invoice.refundTicket(id));
    if (response.type === "success") {
      successAlert(language.refundSuccess);
      await fetchRefundedTickets();
      setRefundTicketInProgress(false);
      setRefundTicketId(undefined);
    } else {
      setRefundTicketInProgress(false);
      setRefundFailed(true);
      sentry.captureException(response.error);
    }
  };

  const getZones = () => {
    const delivery = deliveries && deliveries[0];

    if (!delivery || delivery.type !== "TICKET" || !order || order.productType !== "TICKET") {
      return "";
    }

    const fromZone = (order.zones && order.zones[0]) || order.zoneFrom;
    const toZone = (order.zones && order.zones[1]) || order.zoneTo;
    const { allZones, zones: zonesFull } = delivery;
    const { nrOfZones } = order;

    if (allZones) {
      return zonesLang.allZones;
    }
    if (nrOfZones === 2) {
      return zonesLang.zoneTwo.replace("{zoneFrom}", fromZone).replace("{zoneTo}", toZone);
    }
    if (nrOfZones === 1) {
      return zonesLang.zoneOne.replace("{zoneFrom}", fromZone);
    }
    if (nrOfZones < 1) {
      return zonesLang.unknown;
    }

    if (!zonesFull) {
      return zonesLang.fromToZones(nrOfZones.toString(), `${fromZone} - ${toZone}`);  
    }

    return zonesLang.listZones(nrOfZones.toString(), zonesFull.map((x => zones?.find(y => y.id === x)?.name)).join(", "));

  };

  return (
    <Container width="m" className="components-ticketcounter-order-details" data-test-id="components-ticketcounter-order-details">
      <h1>{language.title}</h1>
      <div className="details" data-test-id="details">
        <div className="row">
          <div className="label">{language.orderedBy}</div>
          <div className="value" data-test-id="ordered-by">
            {order.orderedBy}
          </div>
        </div>
        <div className="row">
          <div className="label">{language.date}</div>
          <div className="value" data-test-id="date">
            {formatDate()}
          </div>
        </div>
        <div className="row">
          <div className="label">{language.medium}</div>
          <div className="value" data-test-id="medium">
            {getMedium()}
          </div>
        </div>
        <div className="row">
          <div className="label">{language.product}</div>
          <div className="value" data-test-id="product">
            {getProduct()}
          </div>
        </div>
        {isTicketOrder && <div className="row">
          <div className="label">{language.profile}</div>
          <div className="value" data-test-id="profile">
            {getProfile()}
          </div>
        </div>}
        {isTicketOrder && <div className="row">
          <div className="label">{language.zones}</div>
          <div className="value" data-test-id="zones">
            {getZones()}
          </div>
        </div>}
        {isTicketOrder &&
          <div className="row">
            <div className="label">{language.count}</div>
            <div className="value" data-test-id="ticket-count">
              {renderTicketCount()}
            </div>
          </div>}
        {isTicketOrder && isRuterMailOrder && (
          <div className="row">
            <div className="label">{language.ticketPrice}</div>
            <div className="value" data-test-id="ticket-price">
              {formatter.number.currency(order.productPrice || 0)}
            </div>
          </div>
        )}

        {isRuterMailOrder && (
          <div className="row">
            <div className="label">{language.orderPrice}</div>
            <div className="value" data-test-id="order-price">
              {formatter.number.currency(order.totalPrice)}
            </div>
          </div>
        )}

        <div className="row">
          <div className="label">{language.invoiceReference}</div>
          <div className="value" data-test-id="invoice-reference">
            {order.invoiceReference}
          </div>
        </div>
        <div className="row">
          <div className="label">{language.personalReference}</div>
          <div className="value" data-test-id="personal-reference">
            {order.personalReference}
          </div>
        </div>
      </div>

      {isTicketOrder && order.deliveryAddress && (
        <div style={{ marginTop: "1rem" }}>
          <div style={{ fontWeight: "500" }}>{language.deliverTo}</div>
          <div>{order.deliveryAddress.addressLine1}</div>
          <div>{order.deliveryAddress.addressLine2}</div>
          <div>
            {order.deliveryAddress.postCode} &nbsp;
            {order.deliveryAddress.postArea}
          </div>
        </div>
      )}

      {isRuterMailOrder && ticketCounterService.allowedRefund && deliveries && deliveries.length === 1 && deliveries[0].type === "TICKET" &&
        <ButtonGroup>
          <Button
            text={language.refundOrder}
            type="button"
            variant="generic"
            onClick={() => {
              setRefundTicketId(deliveries[0].id);
            }}
            disabled={deliveries[0].status !== "pending" || refundedTickets?.matches.some(x => x.delivery.id === deliveries[0].id)}
            data-test-id="refund-impuls-button"
          />
        </ButtonGroup>
      }

      {!isRuterMailOrder && (
        <div data-test-id="tickets-section">
          <h2>{language.tickets}</h2>
          <SearchInput
            placeholder={language.filter}
            value={query}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setQuery(e.target.value)}
          />

          <div className="table-container">
            {!orderIsComplete && <div data-test-id="order-incomplete-warning" className="order-incomplete-warning">{language.orderIsIncompleteWarning}</div>}
            {isTicketOrder && <div className="download-button-container">
              <div style={{ display: "inline-block" }}>
                <ButtonLink
                  text={language.downloadNonFetchedCodeAsCsv}
                  onClick={() => download()}
                  dataTestId="download-unused-pickup-codes"
                />
                {showDownloadError &&
                  <p className="error-message" role="alert" data-test-id="download-unused-error">{language.downloadErrorMessage}</p>
                }
              </div>
            </div>}
            <Table breakpoint="600px">
              <thead>
                <tr>
                  <th style={{ width: "120px" }} scope="col">
                    {language.ticketNumber}
                  </th>
                  <th style={{ width: "100px", textAlign: "right" }} scope="col">
                    {language.price}
                  </th>
                  {order.mediaType === "MOBILE_TICKET" && (
                    <>
                      <th style={{ width: "130px" }} scope="col">
                        {language.pickUpCode}
                      </th>
                      <th style={{ width: "180px" }} scope="col">
                        {language.phone}
                      </th>
                      <th style={{ width: "100px" }} scope="col">
                        {language.downloaded}
                      </th>
                    </>
                  )}
                  {order.mediaType === "TRAVEL_CARD" && (
                    <th style={{ width: "180px" }}>Reisekortnummer</th>
                  )}

                  <th style={{ width: "145px" }} scope="col">
                    {language.status}
                  </th>
                  {isTicketOrder && <th style={{ width: "130px" }} className="actions" />}
                </tr>
              </thead>
              <tbody>{deliveries?.map(renderRow)}</tbody>
            </Table>
            <TablePagination pagination={pagination} totalRecords={ticketsResult?.totalEntries} />
          </div>
        </div>
      )}


      <Modal
        data-test-id="cancel-pending-ticket-modal"
        isOpen={Boolean(cancelTicketId)}
        title={language.cancelTicket}
        handleClose={() => setCancelTicketId(undefined)}
        className="cancel-pending-ticket-modal"
      >
        <p>{language.confirmPendingTicketCancel}</p>
        {cancelTicketFailed && (
          <Message style={{ marginTop: "2rem" }} skin="danger" title={language.cancelTicketFail} data-test-id="error-message" />
        )}
        <ButtonGroup className="button-group">
          <Button
            text={language.yes}
            type="button"
            variant="primary"
            data-test-id="confirm-button"
            onClick={cancelTicket}
            loading={cancelTicketInProgress}
          />
          <Button text={language.cancel} variant="cancel" type="button" data-test-id="cancel-button" onClick={() => setCancelTicketId(undefined)} />
        </ButtonGroup>
      </Modal>

      {refundTicketId &&
        <Modal
          isOpen
          handleClose={() => setRefundTicketId(undefined)}
          title={language.refund}
          data-test-id="refund-modal" >
          <p>{language.confirmRefund}</p>
          {refundFailed && (
            <Message style={{ marginTop: "2rem" }} skin="danger" title={language.refundFailed} data-test-id="error-message" />
          )}
          <ButtonGroup>
            <ButtonGroup className="button-group">
              <Button
                text={language.yes}
                type="button"
                variant="primary"
                data-test-id="confirm-button"
                onClick={() => { refundTicket(refundTicketId); }}
                loading={refundTicketInProgress}
              />
              <Button text={language.cancel} variant="cancel" type="button" data-test-id="cancel-button" onClick={() => setCancelTicketId(undefined)} />
            </ButtonGroup>
          </ButtonGroup>
        </Modal>}
    </Container>
  );
};
export default OrderDetails;
