import { endOfMonth, format, startOfMonth } from "date-fns";
import { hasValidAgreement } from "src/common/agreements";
import { Payroll } from "src/common/api/companyAgreementApi/Payroll";
import { TicketZonesLanguageResource } from "src/components/common/text/ticketZones/lang-resource";
import { getZonesText } from "src/components/common/text/ticketZones/TicketZones";
import { AgreementSubTypeId } from "src/types/AgreementSubTypeId";
import { AuthCompany, WidgetAccessOptions } from "src/types/AuthCompany";
import { Widget } from "src/types/companyAgreement/Widget";
import { Contact } from "src/types/Contact";
import { CompanyRoleType } from "src/types/user/CompanyRoleType";
import { UserRoleType } from "src/types/user/UserRoleType";
import { EnvName } from "../EnvName";
import { Tenant } from "../Tenant";
import { InvoiceRef, mapInvoiceRef } from "./InvoiceRef";
import { HasNoService } from "./Services";

interface WidgetAccess {
  getIframeUrl: (widget: Widget, preview: boolean, theme: "light" | "dark") => string,
  getLinkUrl: (widget: Widget) => string,
}


interface ExportColumn {
  headerName: string;
  wrapAsFormula: boolean;
  getData: (data: Payroll, ticketZonesLanguage: TicketZonesLanguageResource) => string
}
interface PayrollSettings {
  showDatePicker: boolean
  export: {
    delimiter: string;
    showHeader: boolean;
    columns: ExportColumn[]
    fileSuffix: "csv" | "ovf"
  }
}

export interface HasCompanyTicketService {
  hasService: true,
  invoiceRefs: InvoiceRef[]
  widgetSettings: WidgetSettings
  agreementNumber: string
  allowOrderTicket: boolean
  payRoll: PayrollSettings
  isObosTestCustomer: boolean
  hasIncreasedExpiryDate: boolean
  widgetUrls: WidgetAccess
  showInfoPage: boolean
  hasMultipleProfiles: boolean
  singleZoneHasAdditionalZone: boolean
}

export interface WidgetSettings {
  emailForTicketRequests: string | null;
  emailForInvoicePreview: string | null;
  widgetAccess: WidgetAccessOptions;
  employeeIdLabel: string;
}

export type CompanyTicketService = HasCompanyTicketService | HasNoService;

const getIframeUrl = (widget: Widget, preview: boolean, theme: "light" | "dark", env: EnvName): string => {
  const { clientId, clientSecret } = widget;

  let origin = "http://localhost:4000";
  if (env === "test") {
    origin = "https://bigcustomer-widget.test.transhub.io";
  } else if (env === "stage") {
    origin = "https://bigcustomer-widget.stage.transhub.io";
  } else if (env === "prod") {
    origin = "https://bigcustomer-widget.transhub.io";
  }

  let url = `${origin}/?id=${clientId}&secret=${clientSecret}&theme=${theme}`;
  if (preview) {
    url += "&preview=true";
  }
  return url;
};


const getLinkUrl = (widget: Widget, env: EnvName) => {
  const { clientId, clientSecret } = widget;

  let origin = "http://localhost:4000";
  if (env === "test") {
    origin = "https://bigcustomer-widget.test.transhub.io";
  } else if (env === "stage") {
    origin = "https://bigcustomer-widget.stage.transhub.io";
  } else if (env === "prod") {
    origin = "https://bigcustomer-widget.transhub.io";
  }

  return `${origin}/?id=${clientId}&secret=${clientSecret}&viewmode=link`;
};

const calculateWidgetAcces = (env: EnvName): WidgetAccess => ({
  getIframeUrl: (widget: Widget, preview: boolean, theme: "light" | "dark") => getIframeUrl(widget, preview, theme, env),
  getLinkUrl: (widget: Widget) => getLinkUrl(widget, env),
});

const getPayrollSettings = (env: EnvName, employeeIdLabel: string, companyId: string): PayrollSettings => {
  const defaultPayrollSettings: PayrollSettings = {
    showDatePicker: env !== "prod",
    export: {
      delimiter: ";",
      showHeader: true,
      fileSuffix: "csv",
      columns: [
        { headerName: "Etternavn", wrapAsFormula: false, getData: (data: Payroll) => data.order.lastName },
        { headerName: "Fornavn", wrapAsFormula: false, getData: (data: Payroll) => data.order.firstName },
        { headerName: "Telefonnr", wrapAsFormula: false, getData: (data: Payroll) => data.order.phone },
        { headerName: employeeIdLabel, wrapAsFormula: true, getData: (data: Payroll) => data.order.employeeId },
        { headerName: "Soner", wrapAsFormula: false, getData: (data: Payroll, ticketZonesLanguage: TicketZonesLanguageResource) => getZonesText({ language: ticketZonesLanguage, nrOfZones: data.order.nrOfZones, zones: data.order.zones }) },
        { headerName: "Månedspris", wrapAsFormula: false, getData: (data: Payroll) => data.orderLinePrice.toFixed(0) },
      ],
    },
  };

  const ovfNumber = (value: number, length: number): string => {
    const firstChar = value >= 0 ? " " : "-";
    return `${firstChar}${value.toString().padStart(length - 1, "0")}`;
  };

  const lovisenbergPayrollSettings: PayrollSettings = {
    showDatePicker: env !== "prod",
    export: {
      delimiter: "",
      showHeader: false,
      fileSuffix: "ovf",
      columns: [
        { headerName: "Selskap", wrapAsFormula: false, getData: () => ovfNumber(1, 6) },
        { headerName: "Region", wrapAsFormula: false, getData: () => ovfNumber(0, 6) },
        { headerName: "Årstall", wrapAsFormula: false, getData: (data: Payroll) => ovfNumber(data.year, 6) },
        { headerName: "Måned", wrapAsFormula: false, getData: (data: Payroll) => ovfNumber(data.month, 6) },
        { headerName: "Lønnsnummer", wrapAsFormula: false, getData: (data: Payroll) => " " + data.order.employeeId.padStart(8, "0") },
        { headerName: "Lønnart", wrapAsFormula: false, getData: () => ovfNumber(506, 6) },
        { headerName: "Stillingsident", wrapAsFormula: false, getData: () => ovfNumber(1, 6) },
        { headerName: "Transaksjonstype", wrapAsFormula: false, getData: () => "TA" },
        { headerName: "Antall", wrapAsFormula: false, getData: () => ovfNumber(1 * 100, 9) },
        { headerName: "Sats", wrapAsFormula: false, getData: (data: Payroll) => ovfNumber(Math.floor(data.orderLinePrice * 100), 9) },
        { headerName: "Beløp", wrapAsFormula: false, getData: (data: Payroll) => ovfNumber(Math.floor(data.orderLinePrice * 100), 9) },
        { headerName: "Tekst", wrapAsFormula: false, getData: () => "Bedriftsbillett".padEnd(20, " ") },
        { headerName: "Skatt", wrapAsFormula: false, getData: () => "X" },
        { headerName: "Tabellnr", wrapAsFormula: false, getData: () => ovfNumber(0, 6) },
        { headerName: "Trinn", wrapAsFormula: false, getData: () => ovfNumber(0, 6) },
        { headerName: "Tillegg", wrapAsFormula: false, getData: () => ovfNumber(0, 6) },
        { headerName: "Stillingsprosent", wrapAsFormula: false, getData: () => ovfNumber(0, 10) },
        { headerName: "Bunkenr", wrapAsFormula: false, getData: () => ovfNumber(0, 9) },
        { headerName: "Bilag", wrapAsFormula: false, getData: () => ovfNumber(0, 9) },
        { headerName: "Personnr", wrapAsFormula: false, getData: () => ovfNumber(0, 12) },
        { headerName: "Reisedato", wrapAsFormula: false, getData: () => ovfNumber(0, 8) },
        { headerName: "Uniqreis", wrapAsFormula: false, getData: () => ovfNumber(0, 9) },
        { headerName: "Årslønn", wrapAsFormula: false, getData: () => ovfNumber(0, 11) },
        { headerName: "Årsramme", wrapAsFormula: false, getData: () => ovfNumber(0, 9) },
        { headerName: "Fradato", wrapAsFormula: false, getData: (data: Payroll) => format(startOfMonth(new Date(data.year, data.month - 1, 1)), "yyyyMMdd") },
        { headerName: "Tildato", wrapAsFormula: false, getData: (data: Payroll) => format(endOfMonth(new Date(data.year, data.month - 1, 1)), "yyyyMMdd") },
      ],
    },
  };

  const LovisenbergProdCompanyId = "1228746";
  const SevenElevenBogstadTestCompanyId = "2000732";

  if (companyId === LovisenbergProdCompanyId || companyId === SevenElevenBogstadTestCompanyId) {
    return lovisenbergPayrollSettings;
  }

  return defaultPayrollSettings;
};

export const calculateCompanyTicketService = (selectedCompany: AuthCompany, contact: Contact, tenant: Tenant, env: EnvName): CompanyTicketService => {
  const agreements = selectedCompany.allAgreements.filter(x => x.subTypeId === AgreementSubTypeId.SUBSCRIPTIONS);

  if (agreements.length === 0) {
    return { hasService: false };
  }

  const isCompanyAdmin = selectedCompany.roles.some(role => role === CompanyRoleType.FreeTicketOrCompanyTicketAdmin)
    || contact.roles.some(role => role === UserRoleType.GlobalCompanyAdmin);

  if (!isCompanyAdmin) {
    return { hasService: false };
  }

  return {
    hasService: true,
    allowOrderTicket: hasValidAgreement([agreements[0]]),
    invoiceRefs: agreements.map(mapInvoiceRef),
    widgetSettings: agreements[0].settings,
    agreementNumber: agreements[0].id,
    payRoll: getPayrollSettings(env, agreements[0].settings.employeeIdLabel, selectedCompany.id),
    isObosTestCustomer: env !== "prod" && selectedCompany?.id === "1229668",
    hasIncreasedExpiryDate: env !== "prod",
    widgetUrls: calculateWidgetAcces(env),
    showInfoPage: tenant === Tenant.Ruter,
    hasMultipleProfiles: tenant === Tenant.Akt,
    singleZoneHasAdditionalZone: tenant === Tenant.Akt,
  };
};

export const getValidCompanyticketService = (service: Partial<CompanyTicketService>): CompanyTicketService => ({
  hasService: true,
  agreementNumber: "",
  allowOrderTicket: false,
  hasIncreasedExpiryDate: false,
  invoiceRefs: [],
  isObosTestCustomer: false,
  payRoll: getPayrollSettings("local", "Ansattnr.", ""),
  widgetSettings: {
    emailForInvoicePreview: "",
    emailForTicketRequests: "",
    employeeIdLabel: "",
    widgetAccess: "DISABLED",
  },
  widgetUrls: {
    getIframeUrl: (widget: Widget, preview: boolean, theme: "light" | "dark") => getIframeUrl(widget, preview, theme, "local"),
    getLinkUrl: (widget: Widget) => getLinkUrl(widget, "local"),
  },
  showInfoPage: true,
  hasMultipleProfiles: false,
  singleZoneHasAdditionalZone: false,
  ...service,
});
