import { useLanguageResource } from "@ruter-as/web-components-and-tools";
import { Fieldset, RadioButton, RadioButtonGroup, Select } from "@ruter-ds/rds-components";
import React, { ChangeEvent, useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useAuthContextAuthenticated } from "src/AuthContext";
import { formFieldsLanguageResource } from "src/common/form-fields-language-resource";
import { Zone } from "../../../common/api/commonTypes/Zone";
import "./ZoneInput.scss";

interface Props {
  zones: Zone[];
}

const ZONE_FROM_NAME = "zoneFrom";
const ZONE_TO_NAME = "zoneTo";

const ZoneInput: React.FC<Props> = ({ zones }) => {
  const authContext = useAuthContextAuthenticated();
  const { register, setValue } = useFormContext();
  const zoneFrom = useWatch({ name: ZONE_FROM_NAME });
  const zoneTo = useWatch({ name: ZONE_TO_NAME });
  const formLang = useLanguageResource(formFieldsLanguageResource);
  const {defaultZones} = authContext.features;

  if (!defaultZones.hasDefaultZone) {
    throw new Error("Tenant does not have default zones");
  }

  const changeNumberOfZones = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    if (value === "1") {
      setValue(ZONE_FROM_NAME, defaultZones.fromZone);
      setValue(ZONE_TO_NAME, defaultZones.fromZone);
    } else if (value === "2") {
      setValue(ZONE_FROM_NAME, defaultZones.fromZone);
      setValue(ZONE_TO_NAME, defaultZones.toZone);
    } else {
      setValue(ZONE_FROM_NAME, defaultZones.allZonesFrom);
      setValue(ZONE_TO_NAME, defaultZones.allZonesTo);
    }
  };

  const sortZonesByName = (zoneA: Zone, zoneB: Zone) => {
    if (zoneA.name > zoneB.name) {
      return 1;
    }
    if (zoneA.name < zoneB.name) {
      return -1;
    }
    return 0;
  };

  const getZoneById = (id: string): Zone => {
    const output = zones.find((x) => x.id === id);
    if (!output) {
      throw new Error(`could not find zoneId: ${id}`);
    }
    return output;
  };

  const getZoneByName = (name: string) => {
    const output = zones.find((x) => x.name === name);
    if (!output) {
      throw new Error(`could not find zoneName: ${name}`);
    }
    return output;
  };

  const getNeigbours = (zoneName: string) => {
    const zone = getZoneByName(zoneName);

    const neighbourZones: Zone[] = [];

    zone.adjacentZoneIds.forEach((id) => {
      neighbourZones.push(getZoneById(id));
    });

    return neighbourZones;
  };

  const getZoneCount = (zoneFromName: string, zoneToName: string) => {
    if (zoneFromName === defaultZones.allZonesFrom) {
      return 3;
    }

    const zFrom = getZoneByName(zoneFromName);

    if (!zoneToName || zoneToName === zoneFromName) {
      return 1;
    }

    const zTo = getZoneByName(zoneToName);

    if (zFrom.adjacentZoneIds.some((id) => id === zTo.id)) {
      return 2;
    }

    return 3;
  };

  const isNeigbours = (zoneFromName: string, zoneToName: string) => {
    const zoneFromNeighbours = getNeigbours(zoneFromName);
    return zoneFromNeighbours.some((x) => x.name === zoneToName);
  };

  const changeZoneFrom = (event: ChangeEvent<HTMLInputElement>) => {
    const newZoneFromValue = event.target.value;

    const numberOfZones = getZoneCount(zoneFrom, zoneTo);

    if (numberOfZones === 1) {
      setValue(ZONE_FROM_NAME, newZoneFromValue);
      setValue(ZONE_TO_NAME, newZoneFromValue);
    } else {
      const isNeighbours = isNeigbours(newZoneFromValue, zoneTo);

      if (isNeighbours || !zoneTo) {
        setValue(ZONE_FROM_NAME, newZoneFromValue);
      } else {
        const firstNeighbour = getNeigbours(newZoneFromValue)[0];
        setValue(ZONE_FROM_NAME, newZoneFromValue);
        setValue(ZONE_TO_NAME, firstNeighbour.name);
      }
    }
  };

  useEffect(() => {
    register("zoneFrom");
    register("zoneTo");

    if (!zoneFrom && zoneFrom !== null) {
      setValue(ZONE_FROM_NAME, defaultZones.fromZone);
      setValue(ZONE_TO_NAME, defaultZones.fromZone);
    }
  }, [register, setValue, zoneFrom, defaultZones]);

  if (!zoneFrom && zoneFrom !== null) {
    return null;
  }

  const zoneCount = getZoneCount(zoneFrom, zoneTo);
  const displayZoneDropdowns = zoneCount !== 3;
  const displayZoneTo = zoneCount === 2;
  const fromZones = [...zones];

  let toZones: Zone[] = [];
  if (zoneCount === 2) {
    toZones = getNeigbours(zoneFrom);
  }

  fromZones.sort(sortZonesByName);
  toZones.sort(sortZonesByName);

  return (
    <div className="components-common-form-zonefield" data-test-id="zone-input">
      <RadioButtonGroup
        groupTitle={formLang.numberOfZones}
        radioName="default"
        selectedValue={zoneCount.toString()}
        onChange={(e: ChangeEvent<HTMLInputElement>) => changeNumberOfZones(e)}
        data-test-id="numberOfZonesSelector"
      >
        <RadioButton buttonText={formLang.oneZone} value="1" />
        <RadioButton buttonText={formLang.twoZones} value="2" />
        <RadioButton buttonText={formLang.allZones} value="3" />
      </RadioButtonGroup>
      {displayZoneDropdowns && (
        <Fieldset legend={formLang.forZone}>
          <div>
            <Select value={zoneFrom} name="zoneFrom" onChange={(e: ChangeEvent<HTMLInputElement>) => changeZoneFrom(e)}>
              {fromZones.map((zone) => (
                <option key={zone.name} value={zone.name}>
                  {zone.name}
                </option>
              ))}
            </Select>
          </div>
          {displayZoneTo && (
            <div>
              <Select value={zoneTo} name="zoneTo" onChange={(e: ChangeEvent<HTMLInputElement>) => setValue(ZONE_TO_NAME, e.target.value)}>
                {toZones.map((zone) => (
                  <option key={zone.name} value={zone.name}>
                    {zone.name}
                  </option>
                ))}
              </Select>
            </div>
          )}
        </Fieldset>
      )}
    </div>
  );
};

export default ZoneInput;
