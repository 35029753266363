import { apiClient, Button, ButtonGroup, Message, Modal, useLanguageResource } from "@ruter-as/web-components-and-tools";
import { FormGroup } from "@ruter-ds/rds-components";
import * as sentry from "@sentry/react";
import React, { useState } from "react";
import companyAgreementApi from "src/common/api/companyAgreementApi/companyAgreementApi";
import { CompanyAgreementOrder } from "src/common/api/companyAgreementApi/order";
import { formFieldsLanguageResource } from "src/common/form-fields-language-resource";
import employeeTicketsLanguageResource from "./lang-resource";

interface Props {
  onClose: () => void;
  onSuccess: () => void;
  ticket: CompanyAgreementOrder;
}

const ResendPickupConfirmationModal: React.FC<Props> = ({ ticket, onClose, onSuccess }) => {
  const language = useLanguageResource(employeeTicketsLanguageResource);
  const formLang = useLanguageResource(formFieldsLanguageResource);
  const [submitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(false);

  const submit = async () => {
    setSubmitError(false);
    setSubmitting(true);

    const response = await apiClient.request(companyAgreementApi.ticket.sendPickupCode(ticket.id));

    if (response.type === "success") {
      onSuccess();
    } else {
      setSubmitError(true);
      setSubmitting(false);
      sentry.captureException(response.error);
    }
  };

  return (
    <Modal
      isOpen={true}
      title={language.resendPickupCode}
      handleClose={onClose}
      data-test-id="send-pickup-code-modal"
    >
      <FormGroup>
        <p>{language.confirmResendPickupCode}</p>
      </FormGroup>
      {submitError && <FormGroup><Message skin="danger" data-test-id="change-zone-submit-error-message" title={formLang.submitError} /></FormGroup>}
      <ButtonGroup>
        <Button variant="primary" type="button" onClick={submit} text={formLang.yes} loading={submitting} data-test-id="confirm-button" />
        <Button variant="cancel" type="button" text={formLang.cancel} onClick={onClose} data-test-id="cancel-button" />
      </ButtonGroup>
    </Modal>
  );
};

export default ResendPickupConfirmationModal;