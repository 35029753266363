import { apiClient, ButtonGroup, Container, EmailFormInput, FormGroup, Message, PhoneFormInput, TextFormInput, useLanguageResource } from "@ruter-as/web-components-and-tools";
import parsePhoneNumberFromString from "libphonenumber-js/max";
import React, { useCallback, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthContextAuthenticated } from "src/AuthContext";
import userApi from "src/common/api/userApi/userApi";
import { formFieldsLanguageResource } from "src/common/form-fields-language-resource";
import { AgreementSubTypeId } from "src/types/AgreementSubTypeId";
import { CompanyRoleType, parseCompanyRoleType } from "src/types/user/CompanyRoleType";
import { UserRoleType } from "../../../types/user/UserRoleType";
import { CancelButton, SubmitButton } from "../../common/buttons";
import CheckboxInput from "../../common/form-hooks/CheckboxInput";
import { User } from "../users/user";
import userLanguageResource from "./lang-resource";
import RolesInput, { CompanyWithRoles, mapToCompanyWithRoles } from "./RolesInput";
import "./UserPage.scss";
interface FormData {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  globalUserAdmin: boolean;
  globalTravelCardAdmin: boolean;
  globalCustomerSupport: boolean;
  globalSchoolTicketAdmin: boolean
  companies: CompanyWithRoles[];
}


const UserPage: React.FC = () => {
  const lang = useLanguageResource(userLanguageResource);
  const authContext = useAuthContextAuthenticated();
  const formLang = useLanguageResource(formFieldsLanguageResource);
  const [submitting, setSubmitting] = useState(false);
  const [fetching, setFetching] = useState(true);
  const [allCompanies, setAllCompanies] = useState<CompanyWithRoles[]>();
  const [user, setUser] = useState<User | null>(null);
  const [duplicateEmailError, setDuplicateEmailError] = useState<string>();

  const navigate = useNavigate();
  const formMethods = useForm<FormData>({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      globalUserAdmin: false,
      globalTravelCardAdmin: false,
      companies: [],
    },
  });
  const [submitClicked, setSubmitClicked] = useState(false);
  const formValues = formMethods.watch();

  const checkIfUserHasAnyRoles = useCallback((data: FormData) => {
    const hasAnyGlobalRoles = data.globalCustomerSupport || data.globalSchoolTicketAdmin || data.globalTravelCardAdmin || data.globalUserAdmin;
    const hasAnyLocalRoles = data.companies.some(x => x.roles.length > 0);
    return hasAnyGlobalRoles || hasAnyLocalRoles;
  }, []);

  const userHasAnyRoles = checkIfUserHasAnyRoles(formValues);

  const { setValue } = formMethods;
  let { id: userId } = useParams();

  const onSubmit = async (data: FormData) => {
    setSubmitClicked(true);
    setSubmitting(true);
    setDuplicateEmailError(undefined);

    if (!checkIfUserHasAnyRoles(data)) {
      setSubmitting(false);
      return;
    }

    if (!userId) {
      const phone = parsePhoneNumberFromString(data.phone);

      if (!phone || !phone.isValid()) {
        throw new Error("invalid phone number should be impossible as it is validated by the form");
      }

      const userContract = {
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
        phone: phone.nationalNumber,
        phoneCountryCode: `+${phone.countryCallingCode}`,
      };

      const response = await apiClient.request(userApi.auth.createUser(userContract));

      if (response.type === "HttpError" && response.responseStatus === 409) {
        setDuplicateEmailError(data.email);
        setSubmitting(false);
        return;
      }

      if (response.error) {
        setSubmitting(() => {
          throw response.error;
        });
        return;
      }

      userId = response.result.id;
    }

    const roles = [];

    if (data.globalUserAdmin) {
      roles.push(UserRoleType.GlobalUserAdmin);
    }
    if (data.globalTravelCardAdmin) {
      roles.push(UserRoleType.GlobalTravelCardAdmin);
    }
    if (data.globalCustomerSupport) {
      roles.push(UserRoleType.GlobalCompanyAdmin);
    }
    if (data.globalSchoolTicketAdmin) {
      roles.push(UserRoleType.GlobalSchoolTicketAdmin);
    }

    const rolesContract = {
      roles,
      companies: (data.companies || []).map((c) => ({
        companyId: c.companyId,
        roles: c.roles,
      })),
    };

    const response = await apiClient.put(`/user-api/user/${userId}/roles`, rolesContract);
    if (response.error) {
      setSubmitting(() => {
        throw response.error;
      });
    }
    await authContext.userData.reload();

    setSubmitting(false);
    navigate("/brukere");
  };

  useEffect(() => {
    const fetchData = async () => {
      setFetching(true);
      const fetchedCompanies = await apiClient.request(userApi.auth.getCompanies());

      if (fetchedCompanies.error) {
        setAllCompanies(() => { throw fetchedCompanies.error; });
        return;
      }

      setAllCompanies(fetchedCompanies.result.map(mapToCompanyWithRoles));

      if (!userId) {
        setFetching(false);
        return;
      }

      const [userResult, userRoles] = await Promise.all([
        apiClient.request(userApi.auth.getUserById(userId)),
        apiClient.request(userApi.auth.getRolesByUserId(userId)),
      ]);
      if (userResult.error) {
        setUser(() => { throw userResult.error; });
        return;
      }
      if (userRoles.error) {
        setUser(() => { throw userRoles.error; });
        return;
      }

      const globalUserAdmin = userRoles.result.roles.some((x) => x === UserRoleType.GlobalUserAdmin);
      const globalTravelCardAdmin = userRoles.result.roles.some((x) => x === UserRoleType.GlobalTravelCardAdmin);
      const globalCustomerSupport = userRoles.result.roles.some((x) => x === UserRoleType.GlobalCompanyAdmin);
      const globalSchoolTicketAdmin = userRoles.result.roles.some((x) => x === UserRoleType.GlobalSchoolTicketAdmin);

      const companies: CompanyWithRoles[] = userRoles.result.companies.map((role) => ({
        companyId: role.companyId,
        name: role.companyName,
        roles: role.roles.map(parseCompanyRoleType).filter((x): x is CompanyRoleType => x !== "UNKNOWN"),
        organisationNumber: fetchedCompanies.result.find(x => x.id === role.companyId)?.organisationNumber || "",
        isSchool: fetchedCompanies.result.find(x => x.id === role.companyId)?.allAgreements.some(x => x.subTypeId === AgreementSubTypeId.SCHOOLTICKET) || false,
      }));

      setUser(userResult.result);
      setValue("firstName", userResult.result.firstName);
      setValue("lastName", userResult.result.lastName);
      setValue("email", userResult.result.email);
      setValue("phone", userResult.result.phoneCountryCode + userResult.result.phone);
      setValue("globalUserAdmin", globalUserAdmin);
      setValue("globalTravelCardAdmin", globalTravelCardAdmin);
      setValue("globalCustomerSupport", globalCustomerSupport);
      setValue("globalSchoolTicketAdmin", globalSchoolTicketAdmin);
      setValue("companies", companies);
      setFetching(false);
    };

    fetchData();

  }, [userId, setValue]);

  if (fetching) {
    return null;
  }

  if (!allCompanies) {
    return null;
  }

  const getErrorMessage = () => {
    if (duplicateEmailError) {
      return lang.emailTakenMessage;
    } 
    if (!userHasAnyRoles) {
      return lang.noRoles;
    }
  };

  const title = user
    ? lang.titleEdit.replace("$firstName", user.firstName).replace("$lastName", user.lastName).replace("$userName", user.email)
    : lang.titleNew;

  const showEmailError = duplicateEmailError === formValues.email;

  return (
    <Container width="s" className="components-useradmin-user-userpage">
      <h1>{title}</h1>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <div className="narrow">
            <TextFormInput name="firstName" label={formLang.firstName} required minLength={2} maxLength={100} disabled={Boolean(userId)} />
            <TextFormInput name="lastName" label={formLang.lastName} required minLength={2} maxLength={100} disabled={Boolean(userId)} />
            <EmailFormInput name="email" label={formLang.email} required disabled={Boolean(userId)} />
            <PhoneFormInput name="phone" label={formLang.phone} required disabled={Boolean(userId)} />

            {authContext.features.userAdmin.hasGlobalAdminRights && (
              <FormGroup>
                <div className="rds-label">{lang.globalRoles}</div>
                <CheckboxInput name="globalUserAdmin" label={lang.globalUserAdmin} />
                <CheckboxInput name="globalTravelCardAdmin" label={lang.globalTravelCardAdmin} />
                <CheckboxInput name="globalCustomerSupport" label={lang.globalCustomerSupport} />
                <CheckboxInput name="globalSchoolTicketAdmin" label={lang.globalSchoolTicketAdmin} />
              </FormGroup>
            )}
          </div>

          <RolesInput allCompanies={allCompanies} />

          <FormGroup style={{ marginTop: "4rem" }}>
            {submitClicked && (showEmailError || !userHasAnyRoles) && (
              <Message skin="danger" title={getErrorMessage()} data-test-id="error-validation-message" />
            )}
            <ButtonGroup>
              <SubmitButton text={formLang.save} submitting={submitting} />
              <CancelButton onClick={() => navigate("/brukere")} />
            </ButtonGroup>
          </FormGroup>
        </form>
      </FormProvider>
    </Container>
  );
};

export default UserPage;
